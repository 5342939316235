import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useLocation } from "react-router-dom";

import {
  FeatureListPage
} from 'app/modules/apps/feature/FeatureListPage'

import {
  NewFeature
} from 'app/modules/apps/feature/NewFeature'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tính năng',
    path: '/feature/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const FeatureRoutes = () => {

  const { state } = useLocation();

  return (
    <Routes>
      <Route
        element={<><PageTitle breadcrumbs={profileBreadCrumbs} /><Outlet /></>}
      >

        <Route
          path='list'
          element={
            <>
              <PageTitle>Danh sách tính năng</PageTitle>
              <FeatureListPage />
            </>
          }
        />

        <Route
          path='new'
          element={
            <>
              <PageTitle description={state ? 'Chỉnh sửa tính năng' : 'Thêm mới tính năng'}>{state ? 'Chỉnh sửa tính năng' : 'Thêm mới tính năng'}</PageTitle>
              <NewFeature />
            </>
          }
        />


      </Route>



    </Routes >
  )
}
