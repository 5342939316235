import { KTIcon } from "_metronic/helpers"
import { Button, Card, ConfigProvider, Image, Space, Table } from "antd"
import Column from "antd/es/table/Column"
import { MAKE_S3_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"

export const AppointmentFiles = ({ appointment }) => {

  const [fileList, setFileList] = useState([] as any[])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const getFileTypeDescription = (type: string) => {
    switch (type) {
      case 'image':
        return 'Hình ảnh'
      case 'video':
        return 'Video'
      case 'document':
        return 'Tài liệu'
      default:
        return type ? 'Tài liệu' : 'Không xác định'
    }
  }

  const getFileSizeDescription = (size: number) => {
    if (size < 1024) {
      return `${size} B`
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / 1024 / 1024).toFixed(2)} MB`
    } else {
      return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`
    }
  }

  useEffect(() => {
    // const files: any[] = []
    // files.push(...appointment.images.map(item => ({ s3: item, type: 'Hình ảnh', category: 'Hồ sơ' })))
    // files.push(...appointment.videos.map(item => ({ s3: item, type: 'Video', category: 'Hồ sơ' })))
    // files.push(...appointment.documents.map(item => ({ s3: item, type: 'Tài liệu', category: 'Hồ sơ' })))

    // files.push(...appointment.conclusion.images.map(item => ({ s3: item, type: 'Hình ảnh', category: 'Kết quả xét nghiệm bổ sung' })))
    // files.push(...appointment.conclusion.videos.map(item => ({ s3: item, type: 'Video', category: 'Kết quả xét nghiệm bổ sung' })))
    // files.push(...appointment.conclusion.documents.map(item => ({ s3: item, type: 'Tài liệu', category: 'Kết quả xét nghiệm bổ sung' })))

    const files = appointment.media.map(item => ({ ...item, category: 'Hồ sơ' }))
    if (appointment.conclusion.media) {
      files.push(...appointment.conclusion.media.map(item => ({ ...item, category: 'Kết quả xét nghiệm bổ sung' })))
    }

    axios.get(MAKE_S3_API, { params: { list_path: JSON.stringify(files.map(item => item.path)), appointment_id: appointment.uid } })
      .then(response => {
        const data = response.data.data
        files.forEach((file, index) => {
          file.url = data[index]
        })
        setFileList(files)
      })
  }, [appointment])

  return (

    <Card title='Tệp đính kèm' bordered={true}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
            }
          },
        }}>
        <Table
          dataSource={fileList}
          rowKey="path"
          pagination={false}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fs-7 text-gray-600' })}
          size='small'
          locale={{ emptyText: 'Không có dữ liệu' }}
        >
          <Column title="Tên hồ sơ" dataIndex="url"
            render={(_, record: any) => <Space size={1}><KTIcon iconName="file" className="fs-1 me-1 text-primary" /><span className="text-primary fs-7">{record.title || record.origin_name || 'Không tên'}</span></Space>} />
          <Column title="Loại hồ sơ" dataIndex="category" />
          <Column title="Loại" dataIndex="file_type" render={(value) => getFileTypeDescription(value)} />
          <Column title='Kích thước' dataIndex="file_size" render={(value) => getFileSizeDescription(value)} align="end" />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              {record.file_type === 'image' &&
                <Button className="text-hover-primary" icon={<i className="fa-regular fa-eye"></i>}
                  onClick={() => {
                    setPreviewImage(record.url)
                    setPreviewVisible(true)
                  }}
                />}
              <Button className="text-hover-primary" icon={<i className="fa-solid fa-download"></i>} download href={record.url} target="_blank" />
            </Space>
          } />
        </Table>
      </ConfigProvider>
      <Image
        style={{ display: 'none' }}
        preview={{
          visible: previewVisible,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewVisible(value)
          },
        }}
      />
    </Card >
  )
}