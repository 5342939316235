import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import { REGISTER_FIREBASE_API } from "./modules/helpers/Common";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Typography, notification } from "antd";

const firebaseConfig = {
  apiKey: "AIzaSyBZ6H09P5aYr-Dv062ecrX8MCiHUs-OAuk",
  authDomain: "fhc-push-notification.firebaseapp.com",
  projectId: "fhc-push-notification",
  storageBucket: "fhc-push-notification.appspot.com",
  messagingSenderId: "1060714200641",
  appId: "1:1060714200641:web:e050914a2e82057bec50c0"
}
const firebaseVapidKey = "BBtSpZ7vRD2NLSLTOBBynSPU8XXIpBXvBja3ixAyRtAeH6AMwXIv7jWLERAhrOeG_pXSGDLToSKDY_7T4SZHCnE"

const FirebaseContext = createContext({} as any);

export const useFirebase = () => {
  return useContext(FirebaseContext)
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/* Remote config */

const _getRemoteConfig = () => {
  try {
    remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 360000;
    return remoteConfig;
  } catch (err) {
    console.error(err);
  }
}
var remoteConfig = _getRemoteConfig();

export const getConfig = async () => {

  let API_URL = { _source: '', _value: process.env.REACT_APP_API_URL || '' };

  if (!remoteConfig) {
    remoteConfig = _getRemoteConfig();
  }

  if (remoteConfig) {
    await fetchAndActivate(remoteConfig);

    /* @ts-ignore*/
    API_URL = getValue(remoteConfig, process.env.REACT_APP_API_ENV || "dev_env");
  }

  return API_URL;

}


// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const unregisterFirebase = async () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  try {
    const token = await getToken(messaging, { vapidKey: firebaseVapidKey })

    /* await deleteToken(messaging).catch((err) => console.log('delete firebase token err: ', err))*/

    await axios.delete(REGISTER_FIREBASE_API, { data: { token } })

  } catch (e) {
    console.error('unregisterFirebase: ', e)
  }

}

export const registerFirebase = async () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  const token = await getToken(messaging, { vapidKey: firebaseVapidKey })

  await axios.post(REGISTER_FIREBASE_API, { token })
}

export const FirebaseProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const firebaseRegistered = useRef(false)
  const [lastMessage, setLastMessage] = useState(undefined as any)

  useEffect(() => {
    if (lastMessage) {
      api.info({
        message: <Typography.Text strong>{lastMessage.data.zalo_user_name || lastMessage.data.service}</Typography.Text>,
        description: lastMessage.notification.body,
        placement: 'topRight',
        icon: (
          <i className="ki-duotone ki-messages fs-2x text-hover-primary">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </i>)
      })
    }
  }, [lastMessage, api])

  const registerFirebase = useCallback(() => {
    if (!firebaseRegistered.current) {
      getToken(messaging, { vapidKey: firebaseVapidKey })
        .then((currentToken) => {
          if (currentToken) {
            axios.post(REGISTER_FIREBASE_API, { token: currentToken })
            console.log('Registered')
            firebaseRegistered.current = true
            onMessage(messaging, (payload: any) => {
              console.log('Message received. ', payload);
              setLastMessage(payload)
            })
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        })
    }
  }, [])

  const requestNotificationPermission = useCallback(() => {
    // Check if browser support notification
    if (!("Notification" in window)) {
      console.log('Browser does not support notification')
      return false;
    }
    if (Notification.permission === "denied") {
      return false;
    }

    if (Notification.permission === "granted") {
      registerFirebase()
      return true;
    }

    api.info({
      message: 'Bật nhận thông báo',
      description: 'Thông báo cần được bật để nhận được tin nhắn mới',
      placement: 'topRight',
      duration: 0,
      btn: <Button onClick={() => {
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            console.log('notification granted');
            registerFirebase()
          }
        })
        api.destroy()
      }}>Đồng ý</Button>,
      icon: <i className="fa-solid fa-triangle-exclamation"></i>
    })
    return true;
  }, [api, registerFirebase])

  return (
    <>
      {contextHolder}
      <FirebaseContext.Provider value={{ registerFirebase, requestNotificationPermission, lastMessage, setLastMessage }}>
        {children}
      </FirebaseContext.Provider>
    </>
  )
}
