import {Button, Card, Col, InputNumber, Modal, Row, Switch, Typography} from 'antd'
import {DISEASE_PRICE_API, DISEASE_PRICE_MODE_API} from 'app/modules/helpers/Common'
import axios from 'axios'
import {useState} from 'react'

const {Text} = Typography

export const DiseasePrice = ({disease, forceUpdate}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [price, setPrice] = useState(disease.price)
  console.log(disease)

  const updateMode = (checked) => {
    axios.post(DISEASE_PRICE_MODE_API, {
      disease_id: disease.uid,
      type_set_price: checked ? 'PRICE_BASE_DOCTOR' : 'PRICE_BASE_ORG',
    })
      .then(() => forceUpdate())
  }

  const handleOk = () => {
    axios.post(DISEASE_PRICE_API, {disease_id: disease.uid, price: parseInt(price), currency: 'VND'})
      .then(() => {
        setModalOpen(false)
        forceUpdate()
      })
  }

  return (
    <Card
      title="Giá dịch vụ"
      style={{marginTop: 32}}
      extra={<Button onClick={() => setModalOpen(true)}>Thiết lập giá</Button>}
    >
      <Row gutter={[10, 10]}>
        <Col span={6}><Text strong>Giá riêng cho bác sĩ</Text></Col>
        <Col span={18}>
          <Switch
            checkedChildren="Cho phép"
            unCheckedChildren="Không"
            checked={disease.type_set_price === 'PRICE_BASE_DOCTOR'}
            onChange={updateMode}
          />
        </Col>
        <Col span={6}><Text strong>Giá dịch vụ</Text></Col>
        <Col
          span={18}>{disease.price === 0 ? 'Miễn phí' : `${disease.price.toLocaleString()} ${disease.currency}`}</Col>
        <Col span={24} className="fs-7 text-gray-600">Giá dịch vụ là giá chung cho tất cả bác sĩ. Trong trường hợp cần
          thiết lập giá riêng cho bác sĩ, vui lòng chọn "Cho phép" và thiết lập giá riêng cho bác sĩ.</Col>
      </Row>
      <Modal
        title="Thiết lập giá dịch vụ"
        open={modalOpen}
        onCancel={() => {
          setPrice(disease.price)
          setModalOpen(false)
        }}
        onOk={handleOk}
        destroyOnClose
      >
        <InputNumber
          addonAfter="VND"
          value={price}
          onChange={(value) => setPrice(value)}
          step={10000}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          max={1000000000}
          className="w-100" />
      </Modal>
    </Card>
  )
}
