import { Avatar, Button, Card, ConfigProvider, Input, Space, Table, Typography, Popover, Select, Row, Col } from "antd"
import Column from "antd/es/table/Column"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { ORG_API, getRelativeTime } from "../../../helpers/Common"

const { Text } = Typography

const pageSize = 10

export const OrganizationListPage = () => {

  const allStatusOptions = [{ label: 'Hoạt động', value: 'ACTIVE' }, { label: 'Đã khoá', value: 'INACTIVE' }]

  const [orgList, setOrgList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [filter, setFilter] = useState('')

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('');

  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('ACTIVE');

  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    let params = {
      page: page,
      name: searchTerm ? searchTerm : undefined,
      status: statusFilter
    }
    axios.get(ORG_API, { params: params })
      .then(response => response.data.data)
      .then(response => {
        setOrgList(response.items)
        setMaxPage(response.max_page)
      })
  }, [page, searchTerm, statusFilter])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  return (
    <Card
      extra={<Button className="text-hover-primary" icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => navigate('/organization/new')} >Thêm tổ chức</Button>}
      title={
        <Space>
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên tổ chức..." value={filter} onChange={handleFilter} />
          <Popover
            trigger='click'
            placement='bottom'
            open={filterOpen}
            destroyTooltipOnHide={true}
            onOpenChange={(open) => {
              setFilterOpen(open)
            }}
            content={
              <Col style={{ minWidth: '200pt' }}>
                <Space direction="vertical" style={{ width: '100%', marginBottom: 30 }}>
                  <Text strong>Trạng thái:</Text>
                  <Select options={allStatusOptions} value={statusFilter} style={{ width: '100%' }} onSelect={(value) => {
                    if (statusFilter !== value) {
                      setPage(1)
                      setStatusFilter(value)
                    }

                  }} />
                </Space>
                <Row justify='end'>
                  <Button type='primary' onClick={() => setFilterOpen(false)}>Đóng</Button>
                </Row>
              </Col>
            }>
            <Button icon={<i className="fa-solid fa-filter"></i>}>Lọc</Button>
          </Popover>
        </Space>
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            }
          },
        }}>
        <Table
          dataSource={orgList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fw-bolder fs-7' })}
          size='middle'
        >
          <Column title='Tài khoản' dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => navigate(`/organization/id/${record.uid}`)}>
                <Avatar src={record.avatar ? record.avatar : '/media/avatars/blank.png'} size={48} style={{ marginRight: 5 }} />
                <Space.Compact direction='vertical' size='small'>
                  <Text strong>{record.name}</Text>
                </Space.Compact>
              </Space>
          } />
          <Column title="Email" dataIndex="email" />
          <Column title="Ngày tham gia" dataIndex="created_at" render={(value) => getRelativeTime(value)} />
          <Column title="Trạng thái" dataIndex="is_active" render={(value) => value ? 'Đang hoạt động' : 'Đã khoá'} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>} onClick={() => navigate(`/organization/id/${record.uid}`)} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card>
  )
}
