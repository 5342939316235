import { Button, Card, ConfigProvider, DatePicker, Rate, Select, Space, Table, Typography } from 'antd'
import Column from 'antd/es/table/Column'
import { useAuth } from 'app/modules/auth'
import {
  APPOINTMENT_API,
  EMPLOYEE_API,
  ORG_API,
  PATIENT_API,
  getAppointmentStatusLabel,
  getRelativeTime,
} from 'app/modules/helpers/Common'
import { Permission } from 'app/modules/helpers/Permission'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import {
  usePagination
} from 'utils/functional/layout'

const { RangePicker } = DatePicker
const { Text } = Typography
const allStatus = [
  'FINISHED',
  'PAYMENT_PROCESS',
  'PAYMENT_SUCCESS',
  'PAYMENT_FAIL',
  'WAITING_CONCLUSION',
  'WAITING_TEST_RESULT',
  'SUBMITTED_TEST_RESULT',
  'MISSING_CALL',
]

const filterStatus = (currentUser, statusList = allStatus) => {
  let res = statusList;
  if (['DOCTOR'].some(e => e.includes(currentUser?.role || ''))) {
    res = statusList.filter(e => e !== 'PAYMENT_FAIL')
  }
  return res
}

const OrgFilterList = ({ org, setOrg }) => {
  const { auth } = useAuth()
  const [orgList, setOrgList] = useState([{ ...auth?.organization }] as any[])
  const [filter, setFilter] = useState('')
  const [filterDebounce] = useDebounce(filter, 600);

  useEffect(() => {
    axios
      .get(ORG_API, { params: { page: 1, name: filterDebounce } })
      .then((response) => setOrgList(response.data.data.items))
  }, [filterDebounce]);

  const getOrgData = useCallback(({ page, searchTerm }) => {
    return axios
      .get(ORG_API, { params: { page, name: searchTerm } });

  }, []);

  const { onScroll, onSearchDebounce } = usePagination(orgList, setOrgList, getOrgData);

  return (
    <Space direction='vertical' size={4} className='me-5'>
      <Text className='fw-bolder fs-7 text-uppercase'>Tổ chức</Text>
      <Select
        options={orgList.map((org) => ({ value: org.uid, label: org.name }))}
        placeholder='Tất cả'
        style={{ width: 200 }}
        allowClear={false}
        showSearch
        filterOption={false}
        onSearch={(value) => onSearchDebounce(value)}
        onPopupScroll={onScroll}
        onSelect={(value) => {
          setOrg(value)
          setFilter('')
        }}
        onClear={() => setOrg(undefined)}
        value={org}
      />
    </Space>
  )
}

const DoctorFilterList = ({ org, doctor, setDoctor }) => {
  const [doctorList, setDoctorList] = useState([] as any[])
  const [filter, setFilter] = useState('');
  const [filterDebounce] = useDebounce(filter, 600);

  const [_org, _setOrg] = useState('');

  const getDoctorData = useCallback(({ page, searchTerm }) => {
    let params = {
      page,
      fullname: searchTerm,
      contact: searchTerm,
      org_id: org,
      role: JSON.stringify(['DOCTOR'])
    }
    return axios.get(EMPLOYEE_API, { params: params });

  }, [org]);

  const { onScroll, onSearch, onSearchDebounce } = usePagination(doctorList, setDoctorList, getDoctorData);

  useEffect(() => {
    setFilter('')
  }, [org])

  useEffect(() => {

    if (org !== _org) {
      onSearch('');
      _setOrg(org);
    }

  }, [filterDebounce, org, _org, _setOrg, onSearch])

  return (
    <Space direction='vertical' size={4} className='me-5'>
      <Text className='fw-bolder fs-7 text-uppercase'>Bác sĩ</Text>
      <Select
        options={doctorList.map((org) => ({ value: org.uid, label: org.fullname }))}
        placeholder='Tất cả'
        style={{ width: 200 }}
        allowClear
        showSearch
        filterOption={false}
        onSearch={(value) => onSearchDebounce(value)}
        onPopupScroll={onScroll}
        onSelect={(value) => {
          setDoctor(value)
          setFilter('')
        }}
        onClear={() => setDoctor(undefined)}
        value={doctor}
      />
    </Space>
  )
}

export const AppointmentListPage = () => {
  const [appointments, setAppointments] = useState([] as any)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  /* const [filter, setFilter] = useState('')*/
  const [fromDate, setFromDate] = useState(dayjs())
  const [toDate, setToDate] = useState(dayjs().add(2, 'month'))
  /* const [filterDebounce] = useDebounce(filter, 500)*/
  /* const [searchTerm, setSearchTerm] = useState('')*/
  const [patientList, setPatientList] = useState([] as any[])
  const [selectedPatient, setSelectedPatient] = useState(undefined)
  const [filterOptions, setFilterOptions] = useState([] as any[])
  const { auth, permission, currentUser } = useAuth()
  const [selectedOrg, setSelectedOrg] = useState(auth?.organization?.uid)
  const [selectedDoctor, setSelectedDoctor] = useState(undefined)
  const navigate = useNavigate()
  const pageSize = 10

  useEffect(() => {
    axios
      .get(APPOINTMENT_API, {
        params: {
          target_patient_id: selectedPatient,
          target_org_id: selectedOrg,
          target_doctor_id: selectedDoctor,
          from_datetime: fromDate.startOf('day').format('YYYY/MM/DD HH:mm:ss'),
          to_datetime: toDate.endOf('day').format('YYYY/MM/DD HH:mm:ss'),
          status:
            filterOptions.length > 0 ? JSON.stringify(filterOptions) : JSON.stringify(filterStatus(currentUser)),
          page: page,
          per_page: pageSize,
        },
      })
      .then((response) => response.data.data)
      .then((data) => {
        setAppointments(data.items)
        setMaxPage(data.max_page)
      })
  }, [page, selectedPatient, fromDate, toDate, filterOptions, selectedOrg, selectedDoctor, currentUser])

  useEffect(() => {
    setTimeout(() => setPage(1), 200)
  }, [setPage, selectedPatient, fromDate, toDate, filterOptions, selectedOrg, selectedDoctor]);

  /* useEffect(() => {
   *   let params = {
   *     page: 1,
   *     fullname: searchTerm ? searchTerm : undefined,
   *     contact: searchTerm ? searchTerm : undefined,
   *     role: '["PATIENT"]',
   *   }
   *   axios
   *     .get(PATIENT_API, {params: params})
   *     .then((response) => response.data.data)
   *     .then((response) => {
   *       setPatientList(response.items)
   *     })
   * }, [searchTerm])*/

  const getPatientData = useCallback(({ page, searchTerm }) => {
    let params = {
      page,
      fullname: searchTerm ? searchTerm : undefined,
      contact: searchTerm ? searchTerm : undefined,
      role: '["PATIENT"]',
    }
    return axios
      .get(PATIENT_API, { params })

  }, []);

  const { onScroll, onSearchDebounce } = usePagination(patientList, setPatientList, getPatientData);

  /* useEffect(() => {
   *   setPage(1)
   *   setSearchTerm(filterDebounce)
   * }, [filterDebounce])*/

  return (
    <>
      <Card className='mb-5' styles={{ body: { paddingBlock: 15, paddingInline: 32 } }}>
        <Space className='mb-5'>
          {permission(Permission.SwichOrganization) && (
            <OrgFilterList
              org={selectedOrg}
              setOrg={(org) => {
                if (org && org !== selectedOrg) {
                  setSelectedOrg(org)
                  setSelectedDoctor(undefined)
                }
              }}
            />
          )}
          {permission(Permission.ManageOrganization) && (
            <DoctorFilterList
              org={selectedOrg}
              doctor={selectedDoctor}
              setDoctor={setSelectedDoctor}
            />
          )}
        </Space>

        <Space>
          <Space direction='vertical' size={4} className='me-5'>
            <Text className='fw-bolder fs-7 text-uppercase'>Khách hàng</Text>

            <Select
              options={patientList.map((patient) => ({
                value: patient.uid,
                label: patient.fullname,
              }))}
              placeholder='Tất cả'
              style={{ width: 200 }}
              allowClear
              showSearch
              filterOption={false}
              onSearch={(value) => onSearchDebounce(value)}
              onSelect={(value) => setSelectedPatient(value)}
              onClear={() => setSelectedPatient(undefined)}
              onPopupScroll={onScroll}

            />
          </Space>
          <Space direction='vertical' size={4} className='me-5'>
            <Text className='fw-bolder fs-7 text-uppercase'>Trạng thái</Text>
            <Select
              mode='multiple'
              options={filterStatus(currentUser).map((item) => ({
                value: item,
                label: getAppointmentStatusLabel(item),
              }))}
              style={{ width: 300 }}
              placeholder='Tất cả'
              maxTagCount='responsive'
              allowClear
              onClear={() => setFilterOptions([])}
              maxTagPlaceholder={(values) => `+${values.length}`}
              onChange={(_, options: any) => setFilterOptions(options.map((item) => item.value))}
            />
          </Space>
          <Space direction='vertical' size={4}>
            <Text className='fw-bolder fs-7 text-uppercase'>Thời gian</Text>
            <RangePicker
              value={[fromDate, toDate]}
              allowClear={false}
              allowEmpty={false}
              onChange={(dates) => {
                if (dates) {
                  setFromDate(dates[0] as any)
                  setToDate(dates[1] as any)
                }
              }}
            />
          </Space>
        </Space>
      </Card>
      <Card>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#fff',
              },
            },
          }}
        >
          <Table
            dataSource={appointments}
            rowKey='uid'
            pagination={{
              defaultCurrent: 1,
              total: maxPage * pageSize,
              showSizeChanger: false,
              onChange: (page) => setPage(page),
              current: page,
            }}
            onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
            onRow={() => ({ className: 'fs-7 text-gray-600' })}
            size='small'
            locale={{ emptyText: 'Không có dữ liệu' }}
          >
            <Column
              title='Khách hàng'
              dataIndex='patient_info'
              render={(value) => value.fullname}
            />
            <Column
              title='Thời gian'
              dataIndex='start_time'
              render={(value) => dayjs(value * 1000).format('DD/MM/YYYY HH:mm')}
            />
            <Column
              title='Thời lượng'
              render={(_, record: any) => `${(record.end_time - record.start_time) / 60} phút`}
            />
            <Column title='Nhóm bệnh' dataIndex={['disease_info', 'name']} />
            <Column
              title='Ngày tạo'
              dataIndex='created_at'
              render={(value) => getRelativeTime(value)}
            />
            <Column
              title='Trạng thái'
              dataIndex='status'
              render={(value) => getAppointmentStatusLabel(value)}
            />
            <Column
              title='Đánh giá'
              dataIndex='rating'
              render={(value) =>
                value > 0 ? <Rate value={value} disabled allowHalf className='fs-7' /> : 'Chưa có đánh giá'}
            />
            <Column
              title='Thao tác'
              align='end'
              render={(_, record: any) => (
                <Space>
                  <Button
                    className='text-hover-primary'
                    icon={<i className='fa-regular fa-pen-to-square fs-5'></i>}
                    onClick={() => {
                      navigate(`/calendar/id/${record.uid}`)
                    }}
                  />
                </Space>
              )}
            />
          </Table>
        </ConfigProvider>
      </Card>
    </>
  )
}
