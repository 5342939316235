import { KTIcon } from "_metronic/helpers"
import { Button, ConfigProvider, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import {getFileTypeDescription, MAKE_S3_URL_API, MEDICAL_FILES_API} from 'app/modules/helpers/Common'
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

const { Text } = Typography

export const PatientFiles = ({ account, refreshCounter = undefined }) => {
  const [page, setPage] = useState(1)
  const [files, setFiles] = useState([] as any[])
  const [maxPage, setMaxPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)

  useEffect(() => {
    setPage(1)
  }, [refreshCounter])

  useEffect(() => {
    axios.get(MEDICAL_FILES_API, { params: { page: page, target_user_id: account } })
      .then((response) => {
        setFiles(response.data.data.items)
        setMaxPage(response.data.data.max_page)
        setPageSize(response.data.data.per_page)
      })
  }, [account, page, refreshCounter])

  const handleS3 = (uid, path) => {
    axios.post(MAKE_S3_URL_API, { medical_file_id: uid, paths: [path] },
      {headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }}
      )
      .then(response => {
        window.open(response.data.data[0], '_blank')
      })
  }

  const fileList = files.map(item => {
    const subFiles = item.media?.map(subItem => ({ ...subItem, title: subItem.title ||
        subItem.origin_name || 'Không tên',
      canDelete: !item.is_default, uploaded_at: item.uploaded_at, uid: item.uid
    }))
    return {
      title: item.title || 'Không tên',
      children: subFiles,
      file_type: 'folder',
      path: item.uid,
      canDelete: !item.is_default,
      uploaded_at: item.uploaded_at
    }
  })

  return (
    <ConfigProvider
      theme={{ components: { Table: { headerBg: '#fff' } } }}>
      <Table
        dataSource={fileList}
        expandable={{ expandRowByClick: true }}
        rowKey="path"
        onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
        onRow={() => ({ className: 'fs-7 text-gray-600' })}
        size='small'
        locale={{ emptyText: 'Không có dữ liệu' }}
        pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
      >
        <Column title="Tên hồ sơ" dataIndex="url"
          render={(_, record: any) => <Space size={1}>
            <KTIcon iconName={record.file_type === 'folder' ? "folder" : 'file'} className="fs-1 me-1 text-primary" />
            <Text className="text-primary fs-7">{record.title || 'Không tên'}</Text>
          </Space>} />
        <Column title="Loại" dataIndex="file_type" render={(value) => getFileTypeDescription(value)} />
        {/* <Column title="Ngày tải lên" dataIndex="file_type" render={(value) => getFileTypeDescription(value)} /> */}
        <Column title='Ngày tải lên' dataIndex='uploaded_at' render={(value) => dayjs(value * 1000).format('DD-MM-YYYY')} />

        <Column title="Thao tác" align="end" render={(_, record: any) => {
          return <Space>
            {record.file_type !== 'folder' &&
              <Button className="text-hover-primary" icon={<i className="fa-solid fa-download"></i>}
                onClick={(e) => {
                  handleS3(record.uid, record.path)
                  e.stopPropagation()
                }} />}
            {record.canDelete ?
              <Button className="text-hover-primary" icon={<i className="fa-solid fa-trash"></i>}
                onClick={(e) => {
                  // handleS3(record.path)
                  // e.stopPropagation()
                }} />
              : <Button className="text-gray-600" icon={<i className="fa-solid fa-lock"></i>} type="text" />
            }
          </Space>
        }} />
      </Table>
    </ConfigProvider>
  )
}
