import { Card, Col, Descriptions, Row } from 'antd'
import dayjs from 'dayjs'
import { getGenderDescription, getAge } from '../../../helpers/Common'
import { SleepWidget } from '../health/SleepWidget'
import { StepWidget } from '../health/StepWidget'

import { WithShareDataPage } from 'app/modules/apps/patient-management/patient/ShareDataPage'

export function PatientOverview({ account }) {

  return (
    account && (
      <>
        <Card
          title='Thông tin cá nhân'
          style={{ marginBottom: 30 }}
        >
          <Descriptions column={2} layout='vertical'>
            <Descriptions.Item label='Tên đầy đủ'>{account.fullname}</Descriptions.Item>
            <Descriptions.Item label='Địa chỉ'>{account.address}</Descriptions.Item>
            <Descriptions.Item label='Ngày sinh'>
              {account.birthday
                ? `${dayjs(account.birthday).format('DD-MM-YYYY')} (${getAge(account.birthday)})`
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Giới tính'>
              {getGenderDescription(account.gender)}
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <WithShareDataPage
          account={account}
          extra={
            <Row gutter={30}>
              <Col span={12}>
                <StepWidget className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
              </Col>
              <Col span={12}>
                <SleepWidget className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
              </Col>
            </Row>
          }
        />
      </>
    )
  )
}
