import { Button, Card, Col, ConfigProvider, List, Row, Space, Typography } from 'antd'
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import Countdown from 'antd/es/statistic/Countdown'
import dayjs from "dayjs"
import {
  ACCOUNT_SUMMARY_API,
  APPOINTMENT_CONVERSATION_API,
  APPOINTMENT_DETAIL_API,
  APPOINTMENT_JOIN_CHECK,
} from 'app/modules/helpers/Common'
import axios from 'axios'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { ChatWidget } from '../chatv2/widget/ChatWidget'
import {
  AppointmentPage
} from 'app/modules/apps/calendar/AppointmentPage'
import { CallWidget } from './CallWidget'
import { PatientWidget } from '../patient-management/patient/PatientWidget'


const menuItems = [
  {
    key: '1',
    label: 'Tin nhắn',
    icon: 'fa-regular fa-comments',
  },
  {
    key: '2',
    label: 'Hồ sơ',
    icon: 'fa-regular fa-user',
  },
  {
    key: '3',
    label: 'Kết luận',
    icon: 'fa-regular fa-clipboard',
  },
]

const { Text } = Typography

const MenuWidget = ({ activeTab, setActiveTab, navigate, state }) => {
  return (
    <Card
      style={{
        height: '100%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        padding: 0,
        margin: 0,
      }}
      styles={{ body: { paddingTop: 15 } }}
    >
      <Button className="tw-w-full tw-mb-2" onClick={() => {

        if (state?.backURL) {
          navigate(state.backURL);
        } else {
          navigate('/dashboard');
        }

      }}>
        <ArrowLeftOutlined />
      </Button>
      <List
        dataSource={menuItems}
        grid={{ gutter: [16, 16], column: 1 }}
        renderItem={(item) => (
          <Button
            size='large'
            style={{
              height: 'auto',
              width: '100%',
              aspectRatio: '1/1',
              borderBottom: activeTab === item.key ? '3px solid black' : undefined,
            }}
            onClick={() => {
              setActiveTab(item.key);

              if (item.key === "3") {
                navigate('files', { relative: 'path' })
              }
            }}
            type='default'
          >
            <Space direction='vertical' size='small'>
              <Text className='fs-4'>
                <i className={item.icon}></i>
              </Text>
              <Text>{item.label}</Text>
            </Space>
          </Button>
        )}
      />
    </Card>
  )
}

const LobbyWidget = ({ appointment, setCanCall }) => {
  console.log(appointment);

  useEffect(() => {
    if (appointment?.start_time) {
      if (dayjs() >= dayjs.unix(appointment.start_time)) {
        setCanCall(true)
      }
    }

  }, [appointment, setCanCall])

  return (
    <Card
      styles={{
        body: {
          height: '100%',
          overflow: 'auto',
          padding: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          alignItems: 'center',
        },
        title: { width: '100%' },
      }}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      title='Cuộc gọi tư vấn'
    >
      <Space
        align='center'
        style={{ width: '100%', height: '100%', justifyContent: 'center', textAlign: 'center' }}
      >
        <ConfigProvider
          theme={{
            components: {
              Statistic: {
                contentFontSize: 48,
                titleFontSize: 36,
              },
            },
          }}
        >
          <Countdown
            title='Thời gian còn lại'
            value={appointment.start_time * 1000}
            onFinish={() => {
              setCanCall(true)
            }}
          />
        </ConfigProvider>
      </Space>
    </Card>
  )
}

export const CallPage = () => {
  const { uid } = useParams()
  const [appointment, setAppointment] = useState(undefined as any)
  const [conversation, setConversation] = useState()
  const [account, setAccount] = useState()
  const [activeTab, setActiveTab] = useState('1')
  const [canCall, setCanCall] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate = useNavigate()
  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    if (initialLoad) {
      axios
        .get(APPOINTMENT_DETAIL_API, { params: { appointment_id: uid } })
        .then((response) => response.data.data)
        .then((response) => {
          setAppointment(response)
          console.log(response)
        })
      axios
        .get(APPOINTMENT_CONVERSATION_API, { params: { appointment_id: uid } })
        .then((response) => response.data.data)
        .then((response) => {
          console.log(response)
          setConversation(response)
        })
      axios
        .get(APPOINTMENT_JOIN_CHECK, { params: { appointment_id: uid } })
        .then((response) => response.data.data)
        .then((response) => {
          if (!response.enable_call && response.remain_time === 0 && initialLoad) {
            navigate(`/calendar/id/${uid}`)
          } else {
            setInitialLoad(false);
          }

        })
    }

  }, [uid, navigate, initialLoad, setInitialLoad])

  useEffect(() => {
    appointment &&
      axios
        .get(ACCOUNT_SUMMARY_API, { params: { target_user_id: appointment.patient_id } })
        .then((response) => response.data.data)
        .then((data) => {
          if (data.birthday) data.birthday = data.birthday.replaceAll('/', '-')
          setAccount(data)
        })
  }, [appointment])

  useEffect(() => {
    if (activeTab === '2') {

      setAccount(prevAcc => {
        setTimeout(() => {
          setAccount(prevAcc)
        }, 100);
        return undefined
      });
    }
  }, [activeTab, setAccount])

  return (
    <Row style={{ margin: 10, height: 'calc(100vh - 20px)' }}>
      <Col flex='80px' style={{ height: '100%' }}>
        <MenuWidget activeTab={activeTab} setActiveTab={setActiveTab} navigate={navigate} state={state} />
      </Col>
      <Col flex='auto' style={{ height: '100%', flexBasis: 0 }}>
        <Row gutter={[10, 0]} style={{ height: '100%', width: '100%' }}>
          <Col sm={12} lg={10} style={{ height: '100%' }}>
            <span className={clsx({ 'd-none': activeTab !== '1' })}>
              {conversation && <ChatWidget user={conversation} fetchCounter={0} />}
            </span>
            <span className={clsx({ 'd-none': activeTab !== '2' })}>
              {account &&
                <PatientWidget account={account} />
              }
            </span>
            <span className={clsx({ 'd-none': activeTab !== '3' })}>
              {activeTab === '3' && (
                <Card
                  title='Kết luận'
                  styles={{
                    body: { height: '100%', overflow: 'auto' },
                  }}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeftWidth: 0,
                  }}
                >
                  {uid && <AppointmentPage />}
                </Card>
              )}
            </span>
          </Col>
          <Col sm={12} lg={14} style={{ height: '100%' }}>
            {appointment &&
              (canCall ? (
                <CallWidget appointment={appointment} />
              ) : (
                <LobbyWidget appointment={appointment} setCanCall={setCanCall} />
              ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
