import {Button, DatePicker, Select, Space} from 'antd'
import {EXECUTE_EXPORT_API, GET_EXPORT_HISTORY_API, ORG_API} from 'app/modules/helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {useCallback, useEffect, useState} from 'react'
import {useAuth} from '../../../auth'
import {CommonTable} from '../../../helpers/CommonTable'
import Column from 'antd/es/table/Column'

enum STATUS {
  "FAILED" =	"Không hoàn thành",
  "FINISHED" =	"Đã hoàn thành",
  "INPROGRESS" ="Đang xử lý"
}

export const SysReport = ({ doctor = undefined as any }) => {

  const  { auth} = useAuth();
  // @ts-ignore
  const orgSelectOptions =[{value: auth.organization.uid, label: auth.organization.name}] as any[]

  const [orgId, setOrgId] = useState(orgSelectOptions[0].value)

  const [forceReload, setForceReload] = useState(0);
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [orgList, setOrgList] = useState(orgSelectOptions)

  const syncOrgList = (page: number, syncedData: []) => {
    if (auth?.role === "SUPER_ADMIN_BRANCH") return;
    axios.get(ORG_API, { params: { page: page } })
      .then(response => response.data.data)
      .then(data => {
        const synced = data.items
          .filter((item: { is_active: boolean }) => item.is_active)
          .map((item: { uid: any; name: any }) => ({value: item.uid, label: item.name}));
        const currentData =  [...syncedData, ...synced]
        if (data.max_page > page ) {
          // @ts-ignore
          syncOrgList(page + 1, currentData)
        } else {
          setOrgList(currentData);
        }
      })
  }

  const executeReport= () => {
    axios.post(EXECUTE_EXPORT_API, {
        org_id: orgId,
        local_year_month: dayjs(currentDate).format('YYYY/MM'),
        local_timezone: 25200,
        type: 'ALL'
      })
      .then(response => response.data.data)
      .then(_ => {
        reloadAction()
      })
  }

  useEffect(() => {
    syncOrgList(1, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = useCallback(async ({page = 2, searchParams= {org_id: orgId}} ) => {

    let res = await axios({
      method: 'get',

      params: {
        /* page,*/
        page: page,
        org_id: searchParams.org_id,
      },
      url: GET_EXPORT_HISTORY_API
    });

    const data = res.data.data;
    res = {
      ...res,
      data: {
        ...res.data,
        data: {
          items: data.items,
          max_page: data.max_page || 1,
          per_page: data.per_page || 10,
        }
      }
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeOrgId = (orgId: any) => {
    setOrgId(orgId)
    reloadAction()
  }
  const columnList = (
    <>
      <Column title="Tổ chức" dataIndex="org_name" render={
        (org_name) => {
          return <span>{org_name}</span>
        }
      } />
      <Column title="Báo cáo tháng" dataIndex="local_year_month" render={
        (local_year_month) => {
          return <span>{dayjs(local_year_month).format('MM/YYYY')}</span>
        }
      } />
      <Column title="Thời gian xuất báo cáo" dataIndex="finised_at" render={
        (finised_at) => {
          return <span>{dayjs(finised_at * 1000).format('HH:mm:ss DD/MM/YYYY')}</span>
        }
      } />
      <Column title="Trạng thái báo cáo" dataIndex="status" render={
        (status) => {
          return <span>{STATUS[status]}</span>
        }
      } />
      <Column title="Thao tác" dataIndex="path_file" render={
        (_, record: any) => {
          return <Button className="text-hover-primary" icon={<i className="fa-solid fa-download"></i>}
                         download href={record.path_file} target="_blank"
                         disabled={record.status !== 'FINISHED'}
          />
        }
      } />
    </>
  )
  const reloadAction = () => setForceReload(x => x + 1)
  const title = <Space>
    <span>Chọn tổ chức</span>
    <Select options={orgList}
            defaultValue={orgList[0].value}
            disabled={auth?.role === "SUPER_ADMIN_BRANCH"}
            style={{minWidth: '200px', maxWidth: '350px'}}
            onChange={onChangeOrgId}/>
    <DatePicker
      disabledDate={(d) => !d || d.isAfter(dayjs())}
      picker={"month"} value={currentDate} onChange={(date) => setCurrentDate(date)} allowClear={false}  />
    <Button icon={<i className="fa-solid fa-rotate"></i>} onClick={reloadAction}/>
  </Space>

  const extra = <Button icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => {
    executeReport()
  }}>Xuất báo cáo</Button>

  return (
    <>
      <CommonTable
        getData={getData}
        columnList={columnList}
        title={title}
        extra={extra}
        forceReload={forceReload}
        searchParams={{org_id: orgId}}
      />
    </>
  )
}
