import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
// import {QueryClient, QueryClientProvider} from 'react-query'
// import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { ConfigProvider, Empty, Typography } from 'antd'

import locale from 'antd/locale/vi_VN';
import dayjs from 'dayjs';

import { getConfig } from 'app/Firebase'


import 'index.css'

dayjs.locale('vi-vn');

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

const init = async () => {
  await getConfig().then(url => {

    /* @ts-ignore*/
    if (url?._value) {
      /* @ts-ignore*/
      setupAxios(axios, url._value)
    } else {
      setupAxios(axios)
    }
  }).catch(err => {
    setupAxios(axios)
  });

  Chart.register(...registerables)

  // const queryClient = new QueryClient()
  const container = document.getElementById('root')
  if (container) {
    createRoot(container).render(
      // <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <ConfigProvider
            /* @ts-ignore*/
            locale={{ ...locale, DatePicker: { ...locale.DatePicker, lang: { ...locale.DatePicker.lang, today: 'Hôm nay', dateFormat: 'DD-MM-YYYY', fieldDateFormat: 'DD-MM-YYYY', fieldMonthFormat: 'MM-YYYY', dateTimeFormat: 'DD-MM-YYYY HH:mm:ss', } }, Calendar: { ...locale.Calendar, lang: { ...locale.DatePicker.lang, today: 'Hôm nay', dateFormat: 'DD-MM-YYYY', fieldDateFormat: 'DD-MM-YYYY', fieldMonthFormat: 'MM-YYYY', dateTimeFormat: 'DD-MM-YYYY HH:mm:ss', } } }}
            renderEmpty={() => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<Typography.Text style={{ color: 'rgba(0, 0, 0, 0.25)' }}>Không có dữ liệu</Typography.Text>}
              ></Empty>
            )}
            theme={{
              token: {
                // borderRadiusLG: 16,
              },
              components: {
                Button: {
                  defaultBg: '#F1FAFF',
                  defaultColor: '#3E97FF',
                  defaultBorderColor: '#FFF0',
                  defaultShadow: '0 0 0 0 #FFF0',
                  fontWeight: 500,
                  // lineWidth: 0,
                  colorPrimary: '#3E97FF',
                  primaryShadow: '0 0 0 0 #FFF0',
                  colorPrimaryHover: '#0095e8',
                },
                InputNumber: {
                  activeBg: '#F1F1F2',
                  colorBgContainer: '#F9F9F9',
                  // lineWidth: 0,
                  hoverBg: '#F9F9F9',
                  colorText: '#4B5675',
                  colorBorder: '#F9F9F9',
                },
                Input: {
                  activeBg: '#F1F1F2',
                  colorBgContainer: '#F9F9F9',
                  // lineWidth: 0,
                  hoverBg: '#F9F9F9',
                  colorText: '#4B5675',
                  colorBorder: '#F9F9F9',
                },
                Select: {
                  colorText: '#4B5675',
                  colorBgContainer: '#F9F9F9',
                  colorBorder: '#F9F9F9',
                  // lineWidth: 0,live
                },
                DatePicker: {
                  colorText: '#4B5675',
                  colorBgContainer: '#F9F9F9',
                  colorBorder: '#F9F9F9',
                  // lineWidth: 0,
                },
                Segmented: {
                  colorText: '#3E97FF',
                  // lineWidth: 0,
                },
              },
            }}
          >
            <AppRoutes />
          </ConfigProvider>
        </AuthProvider>
      </MetronicI18nProvider>
      // <ReactQueryDevtools initialIsOpen={false} />
      // </QueryClientProvider>
    )
  }

};

init();


