import { Card, Col, Row, Statistic, Typography } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/* import {KTIcon} from '../../../../_metronic/helpers'*/
import { PageLink, PageTitle } from '../../../../_metronic/layout/core/PageData'
import { DASHBOARD_SUMMARY_API } from '../../helpers/Common'
import { useAuth } from 'app/modules/auth'
import { AgendaWidget } from './AgendaWidget'
import { UpcomingWidget } from './UpcomingWidget'
import { OrgReport } from './OrgReport'

const { Title } = Typography

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tổng quan',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

/* const updateInfo = [
 *   {
 *     date: '12/10/2023',
 *     title: 'Cập nhật chức năng và sửa lỗi',
 *     content: [
 *       'Cập nhật hiển thị biểu đồ chưa có dữ liệu',
 *     ],
 *   },
 *   {
 *     date: '11/10/2023',
 *     title: 'Cập nhật chức năng và sửa lỗi',
 *     content: [
 *       'Hỗ trợ phân quyền, khoá & mở khoá tài khoản, đặt lại mật khẩu',
 *       'Cập nhật hiển thị và chú thích các biểu đồ',
 *     ],
 *   },
 * ]*/

/* const UpdateItem = ({data}) => {
 *   return (
 *     <>
 *       {
 *         data.map((item, index) =>
 *           <div className="timeline-item" key={index}>
 *             <div className="timeline-line w-40px"></div>
 *             <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
 *               <div className="symbol-label bg-light">
 *                 <KTIcon iconName="underlining" className="fs-2 text-primary" />
 *               </div>
 *             </div>
 * 
 *             <div className="timeline-content mb-10 mt-n1">
 *               <div className="pe-3 mb-5">
 *                 <div className="fs-5 fw-bold">{item.title}</div>
 * 
 *                 <div className="d-flex align-items-center fs-6">
 *                   <div className="text-muted me-2 fs-7">Vào ngày {item.date}</div>
 *                 </div>
 *               </div>
 * 
 *               <List
 *                 dataSource={item.content as string[]}
 *                 renderItem={(item, index) => (
 *                   <List.Item key={index}>
 *                     <Text><Tag color="blue">Mới</Tag>{item}</Text>
 *                   </List.Item>
 *                 )}
 *               />
 *             </div>
 *           </div>,
 *         )
 *       }
 *     </>
 *   )
 * }*/

export const DashboardPage = () => {
  const [data, setData] = useState(undefined as any)
  const navigate = useNavigate()
  const { auth } = useAuth()

  useEffect(() => {
    axios.get(DASHBOARD_SUMMARY_API, { params: { current_datetime: dayjs().format('YYYY/MM/DD HH:mm:ss') } })
      .then(response => response.data.data)
      .then(data => {
        console.log(data)
        setData(data)
      })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Báo cáo hệ thống</PageTitle>
      {(auth?.role === 'OWNER' || auth?.role === 'SUPER_ADMIN_SYS') && data &&
        <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
          <Col xs={24} sm={24} md={12} xl={6}>
            <Card bordered={false} onClick={() => navigate('/organization/list')} hoverable={true}>
              <Statistic
                title={<Title level={5}><i className="fa-solid fa-house-medical"></i> Tổ chức</Title>}
                value={data.total_org}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                suffix={<>(<i className="fa-solid fa-caret-up"></i>{data.increase_30_days_org})</>}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <Card bordered={false} onClick={() => navigate('/patient/list')} hoverable={true}>
              <Statistic
                title={<Title level={5}><i className="fa-solid fa-bed"></i> Khách hàng</Title>}
                value={data.total_patient}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                suffix={<>(<i className="fa-solid fa-caret-up"></i>{data.increase_30_days_patient})</>}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <Card bordered={false} onClick={() => navigate('/account/list')} hoverable={true}>
              <Statistic
                title={<Title level={5}><i className="fa-solid fa-user-doctor"></i> Bác sĩ</Title>}
                value={data.total_employee.DOCTOR}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                suffix={<>(<i className="fa-solid fa-caret-up"></i>{data.increase_30_days_employee.DOCTOR})</>}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} xl={6}>
            <Card bordered={false} onClick={() => navigate('/account/list')} hoverable={true}>
              <Statistic
                title={<Title level={5}><i className="fa-solid fa-user-nurse"></i> Chăm sóc khách hàng</Title>}
                value={data.total_employee.CUSTOMER_CARE}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                suffix={<>(<i className="fa-solid fa-caret-up"></i>{data.increase_30_days_employee.CUSTOMER_CARE})</>}
              />
            </Card>
          </Col>
        </Row>
      }
      {auth?.role === 'DOCTOR' &&
        <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
          <Col xl={12} lg={24}>
            <AgendaWidget />
          </Col>
          <Col xl={12} lg={24}>
            <UpcomingWidget />
          </Col>
        </Row>
      }
      {(auth?.role === 'SUPER_ADMIN_BRANCH' || auth?.role === 'DOCTOR') && <OrgReport />}
      {/* <Card bordered={false}>
          <div className="timeline">
          <UpdateItem data={updateInfo} />
          </div>
          </Card> */}
    </>
  )
}
