import { PageTitle } from "_metronic/layout/core"
import { DISEASE_GROUP_DETAIL_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useReducer, useState } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import { DiseaseHeader } from "./DiseaseHeader"
import { DiseaseOverview } from "./DiseaseOverview"
import { DiseaseEmployeeList } from "./DiseaseEmployeeList"
import { DiseaseFormSelect } from "./DiseaseFormSelect"
import { DiseasePrice } from "./DiseasePrice"
import { useAuth } from 'app/modules/auth'

export const DiseasePage = () => {

  const { auth } = useAuth()

  const [disease, setDisease] = useState(undefined as any)
  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const { uid } = useParams()

  useEffect(() => {
    axios.get(DISEASE_GROUP_DETAIL_API, { params: { disease_id: uid } })
      .then(response => response.data.data)
      .then(data => {
        setDisease(data)
      })
  }, [uid, counter])

  return (
    <Routes>
      <Route
        element={
          <>
            {disease && <PageTitle>{disease.name}</PageTitle>}
            {disease && <DiseaseHeader disease={disease} />}
          </>
        }
      >
        <Route
          index
          element={
            <>
              <DiseaseOverview disease={disease} forceUpdate={forceUpdate} />
              {disease?.registered && ['SUPER_ADMIN_BRANCH'].some(e => e.includes(auth?.role || 'dummy')) &&
                <>
                  <DiseaseFormSelect disease={disease} forceUpdate={forceUpdate} />
                  <DiseasePrice disease={disease} forceUpdate={forceUpdate} />
                  <DiseaseEmployeeList disease={disease} />
                </>
              }
            </>
          }
        />
      </Route>
    </Routes>
  )
}