/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Col, Divider, Row, Space, Typography, Avatar } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { AVATAR_API, getRelativeTime, getRoleDescription } from '../../../helpers/Common';
import dayjs from 'dayjs';
import axios from 'axios';
import { useRef } from 'react';
import { useAuth } from '../../../auth';
import { Permission } from '../../../helpers/Permission';

const { Text, Title } = Typography;

const AccountHeader = ({ account }) => {
  const uploadRef = useRef(null);
  const navigate = useNavigate();
  const { auth, permission } = useAuth();

  let isSelf = auth?.uid === account.uid
  let canChangeAvatar = permission(Permission.ChangeAccountInfo, account.role) || isSelf

  const changeAvatar = (e) => {
    console.log(e.target.files[0])
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    if (!isSelf) {
      formData.append("target_user_id", account.uid);
    }
    axios.put(AVATAR_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        if (response.data.success) {
          navigate(0)
        }
      })
  }

  return (
    <>
      <Row gutter={32}>
        <Col span={8}>
          <Card style={{ width: '100%' }}>
            <Col>
              <Row justify='center'>
                <div className="mb-7
                                tw-relative
                                "
                >
                  <Avatar src={account.avatar ? account.avatar : '/media/avatars/blank.png'} size={128} />

                  {
                    canChangeAvatar &&
                    <>
                      <Row justify="end" className="tw-absolute tw-top-0 tw-right-0">
                        <Button style={{ borderRadius: 100 }} icon={<i className="fa-solid fa-pen"></i>} onClick={() => (uploadRef as any).current.click()} ></Button>
                      </Row>
                      <input type="file" name="avatar" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} ref={uploadRef} onChange={changeAvatar} />
                    </>
                  }
                </div>
              </Row>
              <Row justify='center'>
                <Title level={4} >{account.fullname}</Title>
              </Row>
              <Row justify='center'>
                <div className="mb-3">
                  <div className="badge badge-lg badge-light-primary d-inline">{getRoleDescription(account.role)}</div>
                </div>
              </Row>
            </Col>
            <Divider />
            <Space direction='vertical' style={{ width: "100%" }}>
              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Trạng thái tài khoản</Text>
                <Text className={account.is_active ? 'text-muted' : 'text-danger'}>{account.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text>
              </Space>

              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Tham gia</Text>
                <Text className='text-muted'>{dayjs(account.created_at * 1000).format('DD/MM/YYYY HH:mm:ss')} ({getRelativeTime(account.created_at)})</Text>
              </Space>

              <Space direction='vertical' size={[0, 0]}>
                <Text strong>Online</Text>
                <Text className='text-muted'>{dayjs(account.last_online_at * 1000).format('DD/MM/YYYY HH:mm:ss')} ({getRelativeTime(account.last_online_at)})</Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col span={16}>
          <Outlet />
        </Col>
      </Row>
    </>
  )
}

export { AccountHeader }
