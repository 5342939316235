import { Button, DatePicker, Form, Input, Row, Select, Space } from "antd"
import { DEGREE_LIST_API, PROFILE_API } from "../../../helpers/Common"
import dayjs from "dayjs"
import axios from "axios"
import { useAuth } from "../../../auth"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import TextArea from "antd/es/input/TextArea"

const { Option } = Select

export const AccountOverviewEdit = ({ account, update }) => {
  const [form] = Form.useForm()
  const { uid } = useParams()
  const { currentUser, setCurrentUser } = useAuth()

  const [degreeList, setDegreeList] = useState([] as any[])
  useEffect(() => {
    axios.get(DEGREE_LIST_API).then(
      response => {
        setDegreeList(response.data.data)
      }
    )
  }, [])

  const handleUpdate = () => {
    form.validateFields()
      .then((values) => {
        console.log(values);
        values.degree = values.degree || ''
        axios.put(PROFILE_API, { ...values, birthday: values.birthday?.format('YYYY/MM/DD'), target_user_id: account.uid })
          .then(response => {
            if (!uid) {
              setCurrentUser({
                ...currentUser,
                ...values,
                birthday: values.birthday?.format('YYYY/MM/DD'),
              })
              console.log('updated')
            };
            console.log(response)
            update(true)
          })
      });
  }

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        initialValues={{
          ...account,
          birthday: account.birthday ? dayjs(account.birthday, 'DD-MM-YYYY') : null,
        }}
      >
        <Form.Item label="Họ và tên" name="fullname" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
          <Input value={account && account.fullname} />
        </Form.Item>
        <Form.Item label="Ngày sinh" name="birthday">
          <DatePicker format='DD-MM-YYYY' showNow={false} allowClear={false} />
        </Form.Item>
        {
          account.role === 'PATIENT' ?
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
            :
            <>
              <Form.Item label="Số điện thoại" name="phone" rules={[{ message: 'Thông tin chưa đúng định dạng', pattern: /(?:84|0084|0)[235789][0-9]{1,2}[0-9]{7}(?:[^\d]+|$)/g }, { required: account.role === 'PATIENT', message: 'Bắt buộc điền thông tin' }]}>
                <Input />
              </Form.Item>
              <Form.Item label='Chứng chỉ hành nghề' name='practising_cert'>
                <Input />
              </Form.Item>
              <Form.Item label='Chức danh' name='title'>
                <Select mode="tags" />
              </Form.Item>
              <Form.Item label='Học hàm, học vị' name='degree'>
                <Select options={degreeList.map(degree => ({ value: degree.acronym, label: degree.fullname }))} allowClear />
              </Form.Item>
              <Form.Item name='summary' label='Tiểu sử'>
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
              </Form.Item>
            </>
        }

        <Form.Item label="Địa chỉ" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Quốc tịch" name="nationality">
          <Input />
        </Form.Item>
        <Form.Item label="Giới tính" name="gender">
          <Select>
            <Option value='MALE'>Nam</Option>
            <Option value='FEMALE'>Nữ</Option>
          </Select>
        </Form.Item>
        <Form.Item label="CCCD" name="id_card">
          <Input />
        </Form.Item>

        <Form.Item label="Dân tộc" name="ethnic">
          <Input />
        </Form.Item>
      </Form >
      <Row justify="end">
        <Space>
          <Button onClick={() => update(false)}>Huỷ</Button>
          <Button onClick={handleUpdate}>Lưu thông tin</Button>
        </Space>
      </Row>
    </>
  )
}
