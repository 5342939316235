import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { ACCOUNT_SUMMARY_API } from '../../../helpers/Common'
import { CareplanOverview } from '../careplan/CareplanOverview'
import { PatientActivity } from './PatientActivity'
import { PatientHeader } from './PatientHeader'
import { PatientHealth } from './PatientHealth'
import { PatientNote } from './PatientNote'
import { PatientOverview } from './PatientOverview'
import { PatientProfile } from './PatientProfile'
import { PatientTransaction } from './PatientTransaction'

import { AppointmentMedicalHistory } from "app/modules/apps/calendar/AppointmentMedicalHistory"

import { usePatient } from 'app/modules/apps/patient-management/patient/PatientProvider'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Khách hàng',
    path: '/patient/list',
  }
]

const PatientPage: React.FC = () => {
  const { setAccount: setContextAccount } = usePatient()
  const [account, setAccount] = useState(undefined as any)
  const { uid } = useParams()

  useEffect(() => {
    axios
      .get(ACCOUNT_SUMMARY_API, { params: { target_user_id: uid } })
      .then((response) => response.data.data)
      .then((data) => {
        if (data.birthday) data.birthday = data.birthday.replaceAll('/', '-')
        setAccount(data)
        setContextAccount(data)
      })
  }, [uid, setContextAccount])

  return (
    <Routes>
      <Route
        element={
          <>
            {account && <PatientHeader account={account} />}
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Tổng quan tài khoản'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <PatientOverview account={account} />
            </>
          }
        />
        <Route
          path='health'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Chỉ số sức khoẻ'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <PatientHealth account={account} />
            </>
          }
        />
        <Route
          path='careplan'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Kế hoạch sức khoẻ'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <CareplanOverview account={account} />
            </>
          }
        />
        <Route
          path='profile'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Hồ sơ'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <PatientProfile account={account} />
            </>
          }
        />
        <Route
          path='activity'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Hoạt động</PageTitle>
              <PatientActivity account={account} />
            </>
          }
        />
        <Route
          path='note'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Ghi chú'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <PatientNote account={account} />
            </>
          }
        />
        <Route
          path='transaction'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs} description='Lịch sử giao dịch'>{`Khách hàng ${account?.fullname}`}</PageTitle>
              <PatientTransaction account={account} />
            </>
          }
        />

        <Route
          element={<>
            {account?.uid && <>
              <PageTitle
                breadcrumbs={accountBreadCrumbs}
                description="Tiền sử bệnh">
                {`${account?.fullname}`}
              </PageTitle>
              <AppointmentMedicalHistory appointment={{ patient_info: account }} />
            </>}
          </>}
          path="medicalhistory"
        />
      </Route>
    </Routes>
  )
}

export default PatientPage
