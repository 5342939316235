import { Button, Form, Input, Row, Space } from "antd"
import axios from "axios"
import { ORG_API } from "../../../helpers/Common"

import {
    phoneRegex
} from 'app/modules/apps/account-management/account/NewAccountPage'

export const OrganizationOverviewEdit = ({ org, update }) => {
    const [form] = Form.useForm()

    const handleUpdate = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                axios.put(ORG_API, { ...values, target_org_id: org.uid })
                    .then(response => {
                        console.log(response)
                        update(true)
                    })
            });
    }

    return (
        <>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                initialValues={{
                    ...org,
                }}
            >
                <Form.Item label="Tên" name="name" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input />
                </Form.Item>
                <Form.Item label="Địa chỉ" name="address">
                    <Input />
                </Form.Item>
                <Form.Item label="Trang chủ" name="homepage">
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input />
                </Form.Item>
                <Form.List
                    name="hotline"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    wrapperCol={{ offset: (index === 0 ? 0 : 6) }}
                                    label={index === 0 ? 'Số điện thoại' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Space>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Điền số điện thoại",
                                                },

                                                { message: 'Thông tin chưa đúng định dạng', pattern: phoneRegex }
                                            ]}
                                            noStyle
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Button onClick={() => remove(field.name)}>Xoá</Button>
                                    </Space>
                                </Form.Item>
                            ))}
                            <Form.Item wrapperCol={{ offset: 6 }}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                >
                                    Thêm số điện thoại
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
            <Row justify="end">
                <Space>
                    <Button onClick={() => update(false)}>Huỷ</Button>
                    <Button onClick={handleUpdate}>Lưu thông tin</Button>
                </Space>
            </Row>
        </>
    )
}