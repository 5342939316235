import { Button, Card, Col, Popconfirm, Row, Space, Typography } from 'antd'
import { useState } from 'react'
import { DepartmentAddModal } from '../../department/DepartmentAddModal'
/* import { DepartmentList } from '../../department/DepartmentList'*/
import { OrganizationOverviewEdit } from './OrganizationOverviewEdit'
import { OrganizationOverviewForm } from './OrganizationOverviewForm'
import { useAuth } from 'app/modules/auth'
import axios from "axios"
import { ORG_API, ORG_ACTIVATE_API } from "../../../helpers/Common"

const { Text } = Typography

export function OrganizationOverview({ org, forceUpdate }) {

  const { auth } = useAuth()

  const [isEditing, setIsEditing] = useState(false)
  const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false)

  const handleUpdate = (needRefresh) => {
    setIsEditing(false)
    if (needRefresh) forceUpdate()
  }

  const handleAccountLock = () => {

    axios.delete(ORG_API, { data: { target_org_id: org.uid } })
      .then(response => {
        if (response.data.success) {
          forceUpdate()
        }
      })

    // axios.put(DEACTIVE_ACCOUNT_API, { target_user_id: account.uid })
    //   .then(response => {
    //     if (response.data.success) {
    //       forceUpdate()
    //     }
    //   })
  }

  const handleAccountUnlock = () => {

    axios.put(ORG_ACTIVATE_API, { target_org_id: org.uid })
      .then(response => {
        if (response.data.success) {

          forceUpdate()
        }
      })
    // axios.put(REACTIVE_ACCOUNT_API, { target_user_id: account.uid })
    //   .then(response => {
    //     if (response.data.success) {
    //       forceUpdate()
    //     }
    //   })
  }

  const closeModal = (needRefresh) => {
    setIsDepartmentModalOpen(false)
    if (needRefresh) forceUpdate()
  }

  let canEdit = true //permission(Permission.ChangeAccountInfo, account.role) || isSelf

  return (
    <>
      <Card
        title='Thông tin tài khoản'
        extra={
          <Space>
            {/* {account.role === 'PATIENT' && <Button icon={<i className="fa-solid fa-hospital-user"></i>} onClick={() => navigate(`/patient/id/${account.uid}`)} >Bệnh nhân</Button>} */}
            {canEdit && !isEditing && <Button onClick={() => setIsEditing(true)}>Sửa thông tin</Button>}
          </Space>
        }
      >
        {isEditing ?
          <OrganizationOverviewEdit org={org} update={handleUpdate} /> :
          <OrganizationOverviewForm org={org} />}
      </Card>

      {/* <Card
      title='Chuyên khoa'
      style={{ marginTop: 32 }}
      extra={<Button onClick={() => setIsDepartmentModalOpen(true)}>Thêm khoa</Button>}
      >
      <DepartmentList org={org} />
      </Card> */}
      <DepartmentAddModal
        org={org}
        open={isDepartmentModalOpen}
        close={closeModal}
      />
      <Card
        title='Thiết lập tài khoản'
        style={{ marginTop: 32 }}
        extra={
          // permission(Permission.ChangeAccountInfo, account.role) && 
          !isEditing &&
          <Space>
            {
              org.is_active ?
                <Popconfirm
                  title="Khoá tài khoản"
                  description="Bạn có chắc muốn khoá tài khoản này?"
                  onConfirm={handleAccountLock}
                  okText="Khoá"
                  okButtonProps={{ danger: true }}
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  {['SUPER_ADMIN_BRANCH'].every(e => !e.includes((auth?.role || ''))) && <Button danger={true}>Khoá tài khoản</Button>}
                </Popconfirm>
                :
                <Popconfirm
                  title="Mở khoá tài khoản"
                  description="Bạn có chắc muốn mở khoá tài khoản này?"
                  onConfirm={handleAccountUnlock}
                  okText="Mở khoá"
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  {['SUPER_ADMIN_BRANCH'].every(e => !e.includes((auth?.role || ''))) && <Button>Mở khoá tài khoản</Button>}
                </Popconfirm>
            }
          </Space>

        }
      >
        <Row gutter={[10, 10]}>
          <Col span={6}><Text strong>Trạng thái tài khoản</Text></Col>
          <Col span={18}><Text>{org.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text></Col>
          {/* <Col span={6}><Text strong>Phân quyền</Text></Col> */}
          {/* <Col span={18}><Text>{getRoleDescription(org.role)}</Text></Col> */}
          {/* <Col span={6}><Text strong>Nhóm nội bộ</Text></Col>
          <Col span={18}><Text>{account.branch}</Text></Col> */}
        </Row>
      </Card>
    </>
  )
}