/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Button, Card, Col, Divider, Row, Space, Statistic, Tabs, Tag, Typography } from 'antd'
import axios from 'axios'
/* import dayjs from 'dayjs'*/
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../auth'
import { FOLLOW_API, FOLLOW_CHECK_API, formatPhone, shortListPathologies, getAge } from '../../../helpers/Common'
import { FollowerList } from './FollowerList'

const { Text } = Typography

const getBloodGlucoseValue = (health, unit = 'mmol/L') => {

  let res = NaN;

  const { blood_glucose, blood_glucose_unit } = health || {};

  if (blood_glucose_unit === 'mmol/L') {
    /* debugger;*/
  }

  if (blood_glucose && blood_glucose_unit) {
    if (blood_glucose_unit === unit) {
      res = blood_glucose;
    } else {
      if (unit === 'mmol/L') {
        res = blood_glucose / 18
      } else {
        res = blood_glucose * 18
      }
    }
  }
  return res.toFixed(1)
}

const PatientHeader = ({ account }) => {
  const [isFollowed, setIsFollowed] = useState(false)
  const { updateFollow, currentUser } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  let pathologies = ''; //account.health.pathological_medical_history.split(';');

  const HeaderTabs = [
    { key: `/patient/id/${account.uid}`, label: 'Tổng quan' },
    { key: `/patient/id/${account.uid}/profile`, label: 'Hồ sơ' },
    { key: `/patient/id/${account.uid}/medicalhistory`, label: 'Tiền sử bệnh' },
    { key: `/patient/id/${account.uid}/health`, label: 'Chỉ số sức khoẻ' },
    { key: `/patient/id/${account.uid}/careplan`, label: 'Kế hoạch sức khoẻ' },
    { key: `/patient/id/${account.uid}/note`, label: 'Ghi chú' },
  ]
  if (currentUser && currentUser.role !== 'DOCTOR') {
    HeaderTabs.push(
      { key: `/patient/id/${account.uid}/transaction`, label: 'Lịch sử giao dịch' }
    )
  }

  useEffect(() => {
    axios.get(FOLLOW_CHECK_API, { params: { target_user_id: account.uid } })
      .then(response => response.data.data)
      .then(response => {
        setIsFollowed(response.status)
      })
  }, [account])

  const handleFollow = () => {
    axios.post(FOLLOW_API, { target_user_id: account.uid })
      .then(response => {
        if (response.data.success) {
          setIsFollowed(true)
          updateFollow()
        }
      })
  }

  const handleUnfollow = () => {
    axios.delete(FOLLOW_API, { data: { target_user_id: account.uid } })
      .then(response => {
        if (response.data.success) {
          setIsFollowed(false)
          updateFollow()
        }
      })
  }

  return (
    <Card
      title={
        <Space>
          <Button icon={<i className="fa-solid fa-hospital-user"></i>} type='text'>
            <Text strong>{account.fullname ? account.fullname : `Không tên (${account.phone})`}</Text>
          </Button>
          <Divider type='vertical' />
          {
            account.birthday && <>
              <Text strong>{`${getAge(account.birthday)}`}</Text>
              <Divider type='vertical' />
            </>
          }
          <Button type='link' icon={<i className="fa-solid fa-phone"></i>}>{formatPhone(account.phone)}</Button>
          <Divider type='vertical' />
          <Space size={0} style={{ paddingLeft: 16 }}>
            {pathologies && shortListPathologies(pathologies).map((pathology) => <Tag key={pathology} color='blue' bordered={false}>{pathology}</Tag>)}
          </Space>
        </Space>
      }
      extra={
        <Space>
          {isFollowed ? <Button icon={<i className="fa-solid fa-check"></i>} onClick={handleUnfollow}>Đang theo dõi</Button> : <Button onClick={handleFollow}>Theo dõi</Button>}
          <Button onClick={() => navigate(`/account/patient/id/${account.uid}`)}>Tài khoản</Button>
        </Space>
      }
      style={{ marginBottom: 30 }}
      styles={{ body: { paddingBottom: 0 } }}
    >
      <Space size={30} align='end'>
        <Avatar src={account.avatar ? account.avatar : '/media/avatars/blank.png'} size={160} shape='square' />
        <Space direction='vertical'>
          <FollowerList account={account} />
          <Row gutter={16} style={{ width: '450pt' }}>
            <Col span={8}>
              <Card size="small">
                <Statistic
                  title="Nhịp tim"
                  value={account.health.heart_rate > 0 ? account.health.heart_rate : '--'}
                  valueStyle={{ color: '#3f8600' }}
                  suffix={<Text strong style={{ color: '#3f8600' }}>nhịp/phút</Text>}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <Statistic
                  title="Huyết áp"
                  value={account.health.systolic > 0 ? `${account.health.systolic}/${account.health.diastolic}` : '--/--'}
                  valueStyle={{ color: '#3f8600' }}
                  suffix={<Text strong style={{ color: '#3f8600' }}>mmHg</Text>}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <Statistic
                  title="Đường huyết"
                  value={account.health.blood_glucose > 0 ? getBloodGlucoseValue(account.health) : '--'}
                  valueStyle={{ color: '#3f8600' }}
                  suffix={<Text strong style={{ color: '#3f8600' }}>mmol/L</Text>}
                />
              </Card>
            </Col>
          </Row>
        </Space>
      </Space >
      <Tabs items={HeaderTabs}
        onChange={(activeKey) => navigate(activeKey)}
        tabBarStyle={{ marginBottom: 0 }}
        activeKey={location.pathname}
      />
    </Card >
  )
}

export { PatientHeader }
