import { Button, Card, Col, Divider, Input, Modal, Popconfirm, Row, Select, Space, Typography } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../auth' 
import { DEACTIVE_ACCOUNT_API, REACTIVE_ACCOUNT_API, RESET_PASSWORD_API, ROLE_CHANGE_API, formatPhone, getRoleDescription } from '../../../helpers/Common'
import { Permission, getAvailableRoles } from '../../../helpers/Permission'
import { AccountOverviewEdit } from './AccountOverviewEdit'
import { AccountOverviewForm } from './AccountOverviewForm'
import { ChangePasswordModal } from './ChangePasswordModal'

const { Text } = Typography

export function AccountOverview({ account, forceUpdate }) {

  const [isEditing, setIsEditing] = useState(false)
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false)
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState(account.role)
  const [newPassword, setNewPassword] = useState(undefined as any)
  const { auth, permission } = useAuth()

  const navigate = useNavigate()

  const handleUpdate = (needRefresh) => {
    setIsEditing(false)
    if (needRefresh) forceUpdate()
  }

  const cancelRoleChange = () => {
    setSelectedRole(account.role)
    setIsRoleModalOpen(false)
  }

  const handleRoleChange = () => {
    axios.put(ROLE_CHANGE_API, { target_user_id: account.uid, role: selectedRole })
      .then(response => {
        if (response.data.success) {
          setIsRoleModalOpen(false)
          forceUpdate()
        }
      })
  }

  const handleAccountLock = () => {
    axios.put(DEACTIVE_ACCOUNT_API, { target_user_id: account.uid })
      .then(response => {
        if (response.data.success) {
          forceUpdate()
        }
      })
  }

  const handleAccountUnlock = () => {
    axios.put(REACTIVE_ACCOUNT_API, { target_user_id: account.uid })
      .then(response => {
        if (response.data.success) {
          forceUpdate()
        }
      })
  }

  const handleResetPassword = () => {
    axios.put(RESET_PASSWORD_API, { target_user_id: account.uid })
      .then(response => {
        if (response.data.success) {
          console.log(response.data)
          setNewPassword(response.data.data.new_password)
        }
      })
  }

  let isSelf = auth?.uid === account.uid
  let canEdit = permission(Permission.ChangeAccountInfo, account.role) || isSelf
  let availableRole = getAvailableRoles((auth as any).role, account.role).map(item => ({ label: getRoleDescription(item), value: item }))

  return (
    <>
      <Card
        title='Thông tin tài khoản'
        extra={
          <Space>
            {account.role === 'PATIENT' && <Button icon={<i className="fa-solid fa-hospital-user"></i>} onClick={() => navigate(`/patient/id/${account.uid}`)} >Bệnh nhân</Button>}
            {/*{account.role === 'DOCTOR' && !pathname.includes('account/me') && <Button icon={<i className="fa-solid fa-user-doctor"></i>} onClick={() => navigate(`/doctor/id/${account.uid}`)} >Bác sĩ</Button>}*/}
            {canEdit && !isEditing && <Button onClick={() => setIsEditing(true)}>Sửa thông tin</Button>}
          </Space>
        }
      >
        {isEditing ? <AccountOverviewEdit account={account} update={handleUpdate} /> : <AccountOverviewForm account={account} />}
      </Card>

      <Card title='Tài khoản liên kết' style={{ marginTop: 32 }}>
        <Row gutter={[10, 10]}>
          <Col span={6}><Text strong>Tài khoản Zalo:</Text></Col>
          {/* <Col span={18}><Text>{account.livechat.is_connected ? 'Đã liên kết' : 'Chưa liên kết'}</Text></Col> */}
          {/* {account.livechat.zalo_user_id &&
            <>
              <Col span={6}><Text strong>Zalo ID:</Text></Col>
              <Col span={18}><Link to='/'>{account.livechat.zalo_user_id}</Link></Col>
            </>
          } */}
          {/* <Col span={6}><Text strong>Tài khoản Facebook:</Text></Col>
          <Col span={18}><Text>{account && account.facebook_user_name}</Text></Col>
          <Col span={6}><Text strong>Tài khoản Google:</Text></Col>
          <Col span={18}><Text>{account && account.google_user_name}</Text></Col> */}
        </Row>
      </Card>

      <Card title='Phương thức đăng nhập' style={{ marginTop: 32 }} extra={
        !isEditing && (
          isSelf ?
            <>
              <Button onClick={() => setIsChangePasswordModalOpen(true)}>Đổi mật khẩu</Button>
              <ChangePasswordModal account={account} open={isChangePasswordModalOpen} close={() => setIsChangePasswordModalOpen(false)} />
            </>
            : canEdit &&
            <>
              <Popconfirm
                title='Đặt lại mật khẩu'
                description="Bạn có chắc muốn đặt lại mật khẩu tài khoản này?"
                onConfirm={handleResetPassword}
                okText="Đặt lại"
                okButtonProps={{ danger: true }}
                cancelText="Bỏ qua"
                placement='topRight'
              >
                <Button>Đặt lại mật khẩu</Button>
              </Popconfirm>
              <Modal
                title='Mật khẩu mới'
                open={newPassword}
                style={{ maxWidth: '300pt' }}
                styles={{ body: { marginBlock: 30 } }}
                okText='Đã hiểu'
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => setNewPassword(undefined)}
                onCancel={() => setNewPassword(undefined)}
              >
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Input.Password value={newPassword} bordered visibilityToggle={{ visible: false }}></Input.Password>
                  <Text type='warning'>Mật khẩu chỉ có thể xem một lần duy nhất. Vui lòng lưu lại trước đóng cửa sổ.</Text>
                </Space>
              </Modal>
            </>
        )
      }>
        <Row gutter={[10, 10]}>
          {account.role === 'PATIENT' ?
            <>
              <Col span={6}><Text strong>Số điện thoại:</Text></Col>
              <Col span={18}><Text>{account && formatPhone(account.phone)}</Text></Col>
            </>
            :
            <>
              <Col span={6}><Text strong>Email:</Text></Col>
              <Col span={18}><Text>{account && account.email}</Text></Col>
            </>
          }
          <Col span={6}><Text strong>Mật khẩu:</Text></Col>
          <Col span={18}>Đã thiết lập</Col>
          <Divider dashed={true} style={{ margin: 5 }} />
          <Col span={24}><Text type='secondary'>Tài khoản {getRoleDescription(account.role)} không thể thay đổi {account.role === 'PATIENT' ? 'số điện thoại' : 'địa chỉ email'}. </Text></Col>
        </Row>
      </Card>


      <Card
        title='Thiết lập tài khoản'
        style={{ marginTop: 32 }}
        extra={permission(Permission.ChangeAccountInfo, account.role) && !isEditing &&
          <Space>
            <Button onClick={() => setIsRoleModalOpen(true)}>Phân quyền</Button>
            {
              account.is_active ?
                <Popconfirm
                  title="Khoá tài khoản"
                  description="Bạn có chắc muốn khoá tài khoản này?"
                  onConfirm={handleAccountLock}
                  okText="Khoá"
                  okButtonProps={{ danger: true }}
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  <Button danger={true}>Khoá tài khoản</Button>
                </Popconfirm>
                :
                <Popconfirm
                  title="Mở khoá tài khoản"
                  description="Bạn có chắc muốn mở khoá tài khoản này?"
                  onConfirm={handleAccountUnlock}
                  okText="Mở khoá"
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  <Button>Mở khoá tài khoản</Button>
                </Popconfirm>
            }
            <Modal title='Phân quyền' open={isRoleModalOpen} centered style={{ maxWidth: '300pt' }} onOk={handleRoleChange} onCancel={cancelRoleChange}>
              <Select options={availableRole} style={{ width: '100%', marginBlock: 20 }} value={selectedRole} onChange={(value) => { setSelectedRole(value); console.log(value) }}>
              </Select>
            </Modal>
          </Space>

        }
      >
        <Row gutter={[10, 10]}>
          <Col span={6}><Text strong>Trạng thái tài khoản</Text></Col>
          <Col span={18}><Text>{account.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text></Col>
          <Col span={6}><Text strong>Phân quyền</Text></Col>
          <Col span={18}><Text>{getRoleDescription(account.role)}</Text></Col>
          <Col span={6}><Text strong>Nhóm nội bộ</Text></Col>
          <Col span={18}><Text>{account.branch}</Text></Col>
        </Row>
      </Card>
    </>
  )
}
