import { Button, Form, Row, Select } from "antd"
import { ICD9_LIST_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useState } from "react"
/* import { useDebounce } from "use-debounce"*/

import {
  usePagination
} from 'utils/functional/layout'

export const ServiceInput = ({ subField, remove }) => {
  /* const [filter, setFilter] = useState('')
   * const [filterDebounce] = useDebounce(filter, 500)*/
  /* const [searchTerm, setSearchTerm] = useState('')*/

  const [icd9List, setIcd9List] = useState([] as any[])

  /* useEffect(() => {
   *   axios.get(ICD9_LIST_API, { params: { page: 1, name: searchTerm } })
   *     .then(response => {
   *       // console.log(response.data.data)
   *       setIcd9List(response.data.data.items.map(item => ({ value: item.code, label: item.name })))
   *     })
   * }, [searchTerm])*/

  const getIcd9Data = ({ page, searchTerm }) => {
    return axios.get(ICD9_LIST_API, { params: { page, name: searchTerm } })
  }

  const { onScroll, onSearchDebounce } = usePagination(icd9List, setIcd9List, getIcd9Data, items => {

    const res = items.map(item => ({
      value: item.code,
      label: item.name,
      uid: item.code
    }));

    return res;
  });

  /* useEffect(() => {
   *   setSearchTerm(filterDebounce)
   * }, [filterDebounce])*/

  return (
    <Row>
      <Form.Item required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} style={{ marginBottom: 10, width: 'calc(100% - 40px)' }} {...subField} >
        <Select
          options={icd9List}
          filterOption={false}
          showSearch
          placeholder='Dịch vụ'
          labelInValue
          onPopupScroll={onScroll}
          onSearch={(value) => onSearchDebounce(value)}
        // onSelect={(_, option) => {
        // console.log('select', option)
        // }}
        // style={{ width: 'calc(100% - 40px)' }}
        />
      </Form.Item>
      <Button onClick={remove} icon={<i className="fa-solid fa-trash-can"></i>} style={{ marginLeft: 8 }} />
    </Row>
  )
}
