import { FC, lazy, Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { Button, Result } from 'antd';

import { Client } from 'app/modules/agora/Client'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { AccountRoutes } from '../modules/apps/account-management/AccountRoutes'
import { DashboardPage } from '../modules/apps/dashboard/DashboardPage'
import { PatientRoutes } from '../modules/apps/patient-management/PatientRoutes'
import { SchedulePage } from '../modules/apps/schedule/SchedulePage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
// import { NotePage } from '../modules/apps/notion/NotePage'
import { OrganizationRoutes } from 'app/modules/apps/organization/OrganizationRoutes'
import { Call } from 'app/modules/apps/patient-management/calendar/Call'
import { Content } from '../../_metronic/layout/components/Content'
import { NoteRoutes } from '../modules/apps/note/NoteRoute'

/* import {CalendarPage} from 'app/modules/apps/patient-management/calendar/calendar'*/


import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react'
import { MasterLayout } from 'app/layout/MasterLayout'
import { CalendarRoutes } from 'app/modules/apps/calendar/CalendarRoutes'
import { CallPage } from 'app/modules/apps/call/CallPage'
import { DiseaseRoutes } from 'app/modules/apps/disease/DiseaseRoutes'
import { FormRoutes } from 'app/modules/apps/form/FormRoutes'
import { AppointmentRoutes } from 'app/modules/apps/patient-management/calendar/AppointmentRoutes'
import { CalendarProvider } from 'app/modules/apps/patient-management/calendar/CalendarProvider'

import { ChatRoutes } from 'app/modules/apps/chat/ChatRoutes'
import { DoctorRoutes } from 'app/modules/apps/doctor/DoctorRoutes'
import { NotificationRoutes } from 'app/modules/apps/notification/NotificationRoutes'
import { PatientProvider } from 'app/modules/apps/patient-management/patient/PatientProvider'
import { RatingRoutes } from 'app/modules/apps/rating/RatingRoutes'
import { ReportRoutes } from 'app/modules/apps/report/ReportRoutes'
import { NewsfeedRoutes } from 'app/modules/apps/newsfeed/NewsfeedRoutes'
import { BannerRoutes } from 'app/modules/apps/banner/BannerRoutes'
import { FeatureRoutes } from 'app/modules/apps/feature/FeatureRoutes'
import { SystemReportRoutes } from '../modules/apps/system-report/SystemReportRoutes'

AgoraRTC.setLogLevel(3)

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  const [agoraClient, setAgoraClient] = useState<any>();

  useEffect(() => {
    setAgoraClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))
  }, [setAgoraClient])
  /* const ChatPageV3 = lazy(() => import('../modules/apps/chatv3/ChatPage'))*/

  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path='telemedicine/:uid/*'
        element={
          <CalendarProvider>
            <PatientProvider>
              <AgoraRTCProvider client={agoraClient}>
                <CallPage />
              </AgoraRTCProvider>
            </PatientProvider>
          </CalendarProvider>
        }
      />
      <Route element={<MasterLayout />}>
        <Route
          element={
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          }
        >
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardPage />} />

          <Route path='note/*' element={<NoteRoutes />} />
          <Route path='account/*' element={<AccountRoutes />} />
          <Route path='organization/*' element={<OrganizationRoutes />} />
          <Route path='disease/*' element={<DiseaseRoutes />} />
          <Route path='form/*' element={<FormRoutes />} />
          <Route path='system-report/*' element={<SystemReportRoutes />} />
          <Route path='schedule/*' element={<SchedulePage />} />
          <Route path='patient/*' element={<PatientRoutes />} />
          <Route path='calendar/*' element={<PatientProvider><CalendarRoutes /></PatientProvider>} />
          <Route path='report/*' element={<ReportRoutes />} />
          <Route path='doctor/*' element={<DoctorRoutes />} />
          <Route path='chat/*' element={<ChatRoutes />} />
          <Route path='notification/*' element={<NotificationRoutes />} />
          <Route path='rating/*' element={<RatingRoutes />} />
          <Route path='newsfeed/*' element={<NewsfeedRoutes />} />
          <Route path='banner/*' element={<BannerRoutes />} />
          <Route path='feature/*' element={<FeatureRoutes />} />
          <Route path='no-permission' element={<Result
            status="403"
            title="403"
            subTitle="Xin lỗi, bạn không được phép truy cập trang này."
            extra={<Button type="primary" onClick={() => navigate('/dashboard')}>Quay về màn hình Tổng quan</Button>}
          />} />
          <Route
            path='appointment/*'
            element={
              <CalendarProvider>
                <AppointmentRoutes />
              </CalendarProvider>
            }
          />
          {/* Redirect to Dashboard after success login/registartion */}
        </Route>

        {/* <Route
          path='chat/*'
          element={
            <SuspensedView>
              <ChatProvider>
                <PageTitle>Tin nhắn</PageTitle>
                <ChatPage />
              </ChatProvider>
            </SuspensedView>
          }
        /> */}

        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      <Route
        path='call/:call_params'
        element={
          <Client>
            <Call />
          </Client>
        }
      ></Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
