import React, {useEffect} from 'react'
import {Card, Col, Collapse, CollapseProps, ConfigProvider, Flex, List, Row, Spin, Typography} from 'antd'
import _ from 'lodash'
import './MedicalHistory.scss'
import {FilePreview} from './calendar'
import {MAKE_S3_URL_API} from '../../../helpers/Common'
import axios from 'axios'

const {Title, Text} = Typography

type MedicalHistoryProps = {
  targetUserId: string
  showSpinner: boolean
  medicalHistoryData: any
}
type ListValue = {
  value: string
}
const MedicalHistory: React.FC<MedicalHistoryProps> = (props: MedicalHistoryProps) => {
  const [mediaPath, setMediaPath] = React.useState([])
  const [showHistorySpinner, setShowHistorySpinner] = React.useState(false)
  const [showFamilyHistorySpinner, setShowFamilyHistorySpinner] = React.useState(false)
  const blankItem = {value: '--'}

  const mediaConvertUrl = async (data, type) => {
    return axios.post(MAKE_S3_URL_API, {
        type: type,
        medical_file_id: data.medical_file_id,
        paths: data.media.map(p => p.path),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      },
    )
  }

  useEffect(() => {
    const data = props.medicalHistoryData
    if (data && data.media && data.media.length > 0) {
      setShowHistorySpinner(true)
      mediaConvertUrl(data, '').then(res => {
        data.media = data.media.map((p, i) => ({...p, path: res.data.data[i]}))
        setMediaPath(data.media)
        setShowHistorySpinner(false)
      })

      // data.media = data.media.map((p, i) => ({...p, path: mediaPath[i]}))
    }
    if (data && data.family_histories && data.family_histories.length > 0) {
      setShowFamilyHistorySpinner(true)
      for (let i = 0; i < data.family_histories.length; i++) {
        mediaConvertUrl(data.family_histories[i], 'family').then(mdp => {
          data.family_histories[i].media = data.family_histories[i].media.map((media, i) => ({
            ...media,
            path: mdp.data.data[i],
          }))
          if (i === data.family_histories.length - 1) {
            setShowFamilyHistorySpinner(false)
          }
        })
      }
    }
  }, [props.medicalHistoryData])
  if (props.showSpinner) {
    return (
      <Flex gap="middle" vertical style={{marginTop: 24}}>
        <Spin spinning size={'large'}></Spin>
      </Flex>
    )
  }
  if (!props.targetUserId || !props.medicalHistoryData || !props.medicalHistoryData.family_histories) {
    return <Card className={'medicalPersonal'} style={{borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
      <Text strong>Chưa ghi nhận thông tin tiền sử bệnh.</Text>
    </Card>
  }

  const genDisplayItem = (data: any) => {
    if (!data || !data.pathological_medical_history) return
    return (
      <div style={{display: 'flex', rowGap: 8, flexDirection: 'column'}}>
        <Row>
          <Col span={8}><Text className={'text'} strong>Họ và tên:</Text></Col>
          <Col span={16}><Text className={'list'}>{data.fullname}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Số điện thoại:</Text></Col>
          <Col span={16}><Text className={'list'}>{data.phone || '--'}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Quan hệ với bệnh nhân:</Text></Col>
          <Col span={16}><Text className={'list'}>{data.relationship}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Năm sinh</Text></Col>
          <Col span={16}><Text className={'list'}>{data.yob}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Tiền sử bệnh:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.pathological_medical_history.length > 0 ? data.pathological_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => <List.Item>{item.value}</List.Item>} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Thuốc, TPCN đã dùng:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.drug_medical_history.length > 0 ? data.drug_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => (<List.Item>{item.value}</List.Item>)} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Tiền sử dị ứng:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.allergy_medical_history.length > 0 ? data.allergy_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => <List.Item>{item.value}</List.Item>} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Hút thuốc:</Text></Col>
          <Col span={16}><Text
            className={'list'}>{(_.find(data.living_habits, (o) => o.name === 'smoke') || blankItem).value}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Uống rượu bia:</Text></Col>
          <Col span={16}><Text
            className={'list'}>{(_.find(data.living_habits, (o) => o.name === 'wine') || blankItem).value}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>File đính kèm:</Text></Col>
          <Col span={16}>
            {showFamilyHistorySpinner && <Spin spinning={showFamilyHistorySpinner} size={'small'} />}
            <Flex wrap={'wrap'} gap={'8px'}>
              {[...data.media].map((e, i) => {
                return <FilePreview key={i} url={e.path} text={`file${i + 1}`}></FilePreview>
              })}
              {data.media.length < 1 && <span>--</span>}
            </Flex>
          </Col>
        </Row>
      </div>
    )
  }

  const genDisplay = (data: any) => {
    if (!data) return

    return (
      <div style={{display: 'flex', rowGap: 8, flexDirection: 'column'}}>
        <Row>
          <Col span={8}><Text className={'text'} strong>Tiền sử bệnh:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.pathological_medical_history.length > 0 ? data.pathological_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => <List.Item>{item.value}</List.Item>} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Thuốc, TPCN đã dùng:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.drug_medical_history.length > 0 ? data.drug_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => (<List.Item>{item.value}</List.Item>)} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Tiền sử dị ứng:</Text></Col>
          <Col span={16}>
            <List className={'list'} bordered={false} split={false}
                  dataSource={data.allergy_medical_history.length > 0 ? data.allergy_medical_history as ListValue[] : [blankItem]}
                  renderItem={(item: ListValue) => <List.Item>{item.value}</List.Item>} />
          </Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Hút thuốc:</Text></Col>
          <Col span={16}><Text
            className={'list'}>{(_.find(data.living_habits, (o) => o.name === 'smoke') || blankItem).value}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>Uống rượu bia:</Text></Col>
          <Col span={16}><Text
            className={'list'}>{(_.find(data.living_habits, (o) => o.name === 'wine') || blankItem).value}</Text></Col>
        </Row>
        <Row>
          <Col span={8}><Text className={'text'} strong>File đính kèm:</Text></Col>
          <Col span={16}>
            {showHistorySpinner && <Spin spinning={showHistorySpinner} size={'small'} />}
            <Flex wrap={'wrap'} gap={'8px'}>
              {[...mediaPath].map((e: any, i) => {
                return <FilePreview key={i} url={e.path} text={`file${i + 1}`}></FilePreview>
              })}
              {data.media.length < 1 && <span>--</span>}
            </Flex>
          </Col>
        </Row>
      </div>
    )
  }

  const items: CollapseProps['items'] = props.medicalHistoryData.family_histories.map((item, i) => (
    {
      key: i,
      label: <div>{item.relationship}: {item.fullname} - {item.yob}</div>,
      children: genDisplayItem(item),
    }
  ))
  return (
    <div>
      <Card className={'medicalPersonal'}
            style={{marginTop: 24, borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
        <ConfigProvider theme={{
          components: {
            List: {
              itemPadding: '0',
            },
          },
        }}>
          <Title level={4}>Tiền sử bệnh sử</Title>
          <div style={{display: 'flex', rowGap: 8, flexDirection: 'column'}}>
            {genDisplay(props.medicalHistoryData)}
          </div>
        </ConfigProvider>
      </Card>
      {
        items && items.length > 0 &&
        <div style={{marginTop: 24}}>
          <ConfigProvider
            theme={{
              components: {
                List: {
                  itemPadding: '0',
                },
                Collapse: {
                  headerBg: '#FFF',
                  /* here is your component tokens */
                },
              },
              token: {
                /* here is your global tokens */
                fontSizeLG: 18,
              },
            }}
          >
            <Collapse items={items} expandIconPosition={'end'} className={'collapse-css'} />
          </ConfigProvider>
        </div>
      }
    </div>
  )
}

export default MedicalHistory
