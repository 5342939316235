import {Avatar, Button, Card, ConfigProvider, InputNumber, Popover, Space, Table, Typography} from 'antd'
import Column from 'antd/es/table/Column'
import {DISEASE_EMPLOYEE_API, DISEASE_PRICE_API} from 'app/modules/helpers/Common'
import axios from 'axios'
import {useEffect, useReducer, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {DiseaseEmployeeAddModal} from './DiseaseEmployeeAddModal'

const {Text} = Typography

const PriceSetting = ({disease, record, forceUpdate}) => {
  const [currentPrice, setCurrentPrice] = useState(record.price)
  const [open, setOpen] = useState(false)

  return (
    <Popover
      title={`Giá bác sĩ ${record.fullname}`}
      trigger="click"
      placement="left"
      open={open}
      onOpenChange={(value) => setOpen(value)}
      destroyTooltipOnHide
      content={
        <Space>
          <InputNumber
            addonAfter="VND"
            defaultValue={currentPrice}
            onChange={(value) => setCurrentPrice(value)}
            step={10000}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value?.replace(/\$\s?|(,*)/g, '')}
            min={0}
            max={1000000000}
          />
          <Button onClick={() => {
            axios.post(DISEASE_PRICE_API, {
              disease_id: disease,
              price: currentPrice,
              currency: 'VND',
              target_user_id: record.uid,
            })
              .then(() => {
                setOpen(false)
                forceUpdate()
              })
          }}>Lưu</Button>
          <Button onClick={() => {
            axios.delete(DISEASE_PRICE_API, {data: {disease_id: disease, target_user_id: record.uid}})
              .then(() => {
                setOpen(false)
                forceUpdate()
              })
          }}>Dùng giá chung</Button>
        </Space>
      }
    >
      <Button className="text-hover-primary" icon={<i className="fa-solid fa-sack-dollar fs-5"></i>} onClick={() => {
      }} />
    </Popover>
  )
}

export const DiseaseEmployeeList = ({disease}) => {
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false)
  const [employeeList, setEmployeeList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const pageSize = 10
  const navigate = useNavigate()
  const [updateCounter, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    axios.get(DISEASE_EMPLOYEE_API, {params: {disease_id: disease.uid, page: page, per_page: pageSize}})
      .then(response => response.data.data)
      .then(data => {
        setEmployeeList(data?.items || [])
        setMaxPage(data?.max_page || 0)
      })
  }, [disease, page, updateCounter])

  const handleDelete = (employee) => {
    axios.delete(DISEASE_EMPLOYEE_API, {data: {disease_id: disease.uid, target_user_id: employee}})
      .then(() => {
        forceUpdate()
      })
  }

  const closeModal = (needUpdate = false) => {
    setShowAddEmployeeModal(false)
    if (needUpdate) {
      forceUpdate()
    }
  }

  return (
    <Card
      title="Nhân sự"
      style={{marginTop: 32}}
      extra={<Button onClick={() => {
        setShowAddEmployeeModal(true)
      }}>Thêm nhân sự</Button>}
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            },
          },
        }}>
        <Table
          dataSource={employeeList}
          rowKey="uid"
          pagination={{
            defaultCurrent: 1,
            total: maxPage * pageSize,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
            current: page,
            pageSize: pageSize,
          }}
          onHeaderRow={() => ({className: 'fw-bolder fs-7 text-uppercase'})}
          onRow={() => ({className: 'fw-bolder fs-7'})}
          size="small"
          locale={{emptyText: 'Chưa có nhân sự'}}
        >
          <Column title="Tài khoản" dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => navigate(`/account/id/${record.uid}`)}>
                <Avatar src={record.avatar || '/media/avatars/blank.png'} size={32} style={{marginRight: 5}} />
                <Space.Compact direction="vertical" size="small">
                  <Text strong>{record.fullname}</Text>
                  {/* <Text type='secondary'>{record.phone ? formatPhone(record.phone) : 'Không có thông tin'}</Text> */}
                </Space.Compact>
              </Space>
          } />
          <Column title="Đánh giá" dataIndex="rating"
                  render={(value) => <Space size="small"><i className="fa-solid fa-star"
                                                            style={{color: '#FFAD0F'}}></i>{value || '-'}</Space>} />
          <Column title="Email" dataIndex="email" />
          <Column title="Giá (VND)" align="end" dataIndex="price"
                  render={(value) => value ? `${value.toLocaleString()}` : (value === 0 ? 'Miễn phí' : 'Giá chung')} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              <PriceSetting disease={disease.uid} record={record} forceUpdate={forceUpdate} />
              <Button className="text-hover-primary" icon={<i className="fa-solid fa-trash-can fs-5"></i>}
                      onClick={() => handleDelete(record.uid)} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
      <DiseaseEmployeeAddModal
        open={showAddEmployeeModal}
        close={closeModal}
        disease={disease}
      />
    </Card>
  )
}
