import { Route, Routes } from 'react-router-dom'
import {SystemReportPage} from './SystemReportPage'

export const SystemReportRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<SystemReportPage />}
      />
    </Routes >
  )
}
