import { useParams } from "react-router-dom"
import { PageLink, PageTitle } from "_metronic/layout/core"
import {SysReport} from './export-report/SysReport'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Xuất báo cáo',
    path: '/system-report',
  }]

export const SystemReportPage = () => {
  const { uid } = useParams()
  return (
    <>
      <PageTitle description="Xuất báo cáo" breadcrumbs={accountBreadCrumbs}>Báo cáo</PageTitle>
      <SysReport doctor={uid} />
    </>)

}
