import { Avatar, Button, Card, Result, Space, Spin } from "antd"
import axios from "axios"
import { useEffect, useState, useCallback } from "react"
import { AGORA_TOKEN_URL } from "../patient-management/calendar/core/_requests"

import {
    LocalUser,
    RemoteUser,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useRemoteAudioTracks,
    useRemoteUsers,
    useRTCClient,
    useClientEvent,
    /* useConnectionState*/
} from "agora-rtc-react"
import { useAuth } from "app/modules/auth"
import { PROFILE_API } from "app/modules/helpers/Common"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"

const RemoteUserInfo = ({ uid, micOn, cameraOn, focus }) => {

    const [account, setAccount] = useState(undefined as any);


    useEffect(() => {
        axios.get(PROFILE_API, { params: { target_user_id: uid } })
            .then(response => response.data.data).then(
                data => {
                    console.log(data)
                    setAccount(data)
                }
            )
    }, [uid])

    return account ? (
        focus ?
            <div className="w-100 h-100 text-white fs-5 d-flex flex-nowrap p-1 fw-bold">
                {!cameraOn && <Avatar size={200} src={account.avatar} className="position-absolute mx-auto my-auto top-0 start-0 bottom-0 end-0" style={{ backgroundColor: '#1677FF', fontSize: 75 }} >
                    {account.fullname.charAt(0)}
                </Avatar>
                }
                <span className="d-inline-block text-truncate bg-dark bg-opacity-25 py-2 ps-4 pe-3 ms-auto mt-auto mx-auto" style={{ borderRadius: '1rem' }}>
                    {account?.fullname ?? ''}
                    <span style={{ flex: '0 0 0' }}>
                        <Button
                            className="ms-2"
                            icon={<i className={clsx("fa-solid", micOn ? "fa-microphone" : "fa-microphone-slash", "opacity-100")}></i>}
                            shape='circle'
                            type="primary"
                            size="small"
                            danger={!micOn}
                        />
                    </span>

                </span>
            </div >
            :
            <div className="w-100 h-100 text-white fs-7 d-flex flex-nowrap p-1 flex-column fw-bold">
                {!cameraOn && <Avatar size={100} src={account.avatar || '/media/avatars/blank.png'} className="position-absolute mx-auto my-auto top-0 start-0 bottom-0 end-0" >
                    {account.fullname.charAt(0)}
                </Avatar>
                }
                <span className="ms-auto" style={{ flex: '0 0 0' }}>
                    <Button
                        icon={<i className={clsx("fa-solid", micOn ? "fa-microphone" : "fa-microphone-slash", "opacity-100")}></i>}
                        shape='circle'
                        type="primary"
                        size="small"
                        danger={!micOn}
                    />
                </span>
                <span className="mt-auto d-inline-block text-truncate bg-dark opacity-50 rounded py-1 px-3 mx-auto mw-100">
                    {account?.fullname ?? ''}
                </span>
            </div >
    ) : <></>
}

export const CallWidget = ({ appointment }) => {
    const resolvedClient = useRTCClient();
    const [activeConnection, setActiveConnection] = useState(true)
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const { localMicrophoneTrack, error: localMicError } = useLocalMicrophoneTrack(micOn);
    const { localCameraTrack, error: localCameraError } = useLocalCameraTrack(cameraOn);
    const navigate = useNavigate()
    const { auth } = useAuth()



    useEffect(() => {
        if ((localMicrophoneTrack || localMicError) && (localCameraTrack || localCameraError)) {
            setInitialLoad(false)
        }
    }, [localMicrophoneTrack, localMicError, localCameraTrack, localCameraError]);


    const offlineCb = useCallback(() => {
        setActiveConnection(false);
        setInitialLoad(false)

    }, [setActiveConnection, setInitialLoad]);

    const onlineCb = useCallback(() => {
        setInitialLoad(true);
        setActiveConnection(true);

    }, [setActiveConnection, setInitialLoad]);

    useEffect(() => {

        window.addEventListener('offline', offlineCb);
        window.addEventListener('online', onlineCb);

        return () => {
            window.removeEventListener('offline', offlineCb);
            window.removeEventListener('online', onlineCb);
        }

    }, [offlineCb, onlineCb])


    useJoin(async () => {
        const res = await axios.get(AGORA_TOKEN_URL, { params: { channel_name: appointment.uid, uid: auth?.uid } })
            .then(response => response.data.data)
            .then(data => {
                console.log('???agora', data)
                return {
                    appid: data.app_id,
                    token: data.token,
                    channel: appointment.uid,
                    uid: auth?.uid
                }
            })
        return res
    }, activeConnection, resolvedClient)

    useEffect(() => {
        if (resolvedClient) {
            setInitialLoad(true);
        }
    }, [resolvedClient, setInitialLoad])


    useClientEvent(resolvedClient,
        'connection-state-change',
        (curState, revState, reason) => {

            if (reason === "UID_BANNED" || !navigator.onLine) {
                if (localMicrophoneTrack?.isPlaying) {
                    localMicrophoneTrack?.stop();
                }
                localMicrophoneTrack?.close();



                if (localCameraTrack?.isPlaying) {
                    localCameraTrack?.stop();
                };
                localCameraTrack?.close();
            }


        }

    )

    usePublish([localMicrophoneTrack, localCameraTrack]);

    //remote users
    const remoteUsers = useRemoteUsers();
    const { audioTracks } = useRemoteAudioTracks(remoteUsers);

    // play the remote user audio tracks
    audioTracks.forEach((track) => track.play());

    console.log('???remoteUsers', remoteUsers)

    /* const connectionState = useConnectionState()*/

    return (
        <Card
            styles={{
                body: { height: '100%', overflow: 'auto', padding: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingTop: 1 },
                title: { width: '100%' },
            }}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            title="Cuộc gọi tư vấn"
            actions={[
                <Space>
                    <Button icon={<i className={clsx("fa-solid", cameraOn ? "fa-video" : "fa-video-slash")}></i>} shape='circle' type="primary" size="large" onClick={() => setCamera(!cameraOn)} danger={!cameraOn} disabled={initialLoad || !!localCameraError} />
                    <Button icon={<i className={clsx("fa-solid", micOn ? "fa-microphone" : "fa-microphone-slash")}></i>} shape='circle' type="primary" size="large" onClick={() => setMic(!micOn)} danger={!micOn} disabled={initialLoad || !!localMicError} />
                    <Button icon={<i className="fa-solid fa-phone-slash"></i>} shape='circle' danger type="primary" size="large" disabled={(initialLoad && resolvedClient?.connectionState !== "CONNECTED") &&

                        ['WAITING_TEST_RESULT', 'FINISHED', 'SUBMITTED_TEST_RESULT'].every(s => !appointment.status.includes(s))}

                        onClick={() => {
                            setActiveConnection(false);
                            navigate(`/calendar/id/${appointment.uid}`)
                        }}
                    />
                </Space >

            ]}
        >


            <RemoteUser user={remoteUsers[0]}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    position: 'relative',
                }}
            >
                {remoteUsers.length > 0 ?
                    <RemoteUserInfo uid={remoteUsers[0].uid} micOn={remoteUsers[0].hasAudio} cameraOn={remoteUsers[0].hasVideo} focus={true} />
                    :
                    <div className="bg-white w-100 h-100 d-flex">
                        <Result title="Khách hàng chưa tham gia. Vui lòng chờ trong giây lát..." icon={<Spin size='large' />} className="mx-auto my-auto" />
                    </div>
                }
                <LocalUser
                    audioTrack={localMicrophoneTrack}
                    videoTrack={localCameraTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    playAudio={false}
                    playVideo={cameraOn}
                    className=''
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        maxWidth: '150px',
                        maxHeight: '150px',
                        borderRadius: 8,
                        margin: 20,
                        aspectRatio: '1/1',
                        border: '1px solid #ccc',
                    }}
                >
                    <RemoteUserInfo uid={auth?.uid} micOn={micOn} cameraOn={cameraOn} focus={false} />
                </LocalUser>
            </RemoteUser>
        </Card >
    )
}
