import {Button, Card, DatePicker, Segmented, Space, Spin} from 'antd'
import { APPOINTMENT_API } from "app/modules/helpers/Common"
import axios from "axios"

import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { CalendarDay } from "./CalendarDay"
import { CalendarWeek } from "./CalendarWeek"


export const CalendarPage = () => {
  const [appointments, setAppointments] = useState([] as any[])
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [mode, setMode] = useState('week' as 'week' | 'date')
  const [zoomMode, setZoomMode] = useState('large' as 'small' | 'large')
  const [spinning, setSpinning] = useState(false)

  const syncAppointment = (page, data: []) => {
    let from_date = currentDate.startOf('week').format('YYYY/MM/DD HH:mm:ss')
    let to_date = currentDate.endOf('week').format('YYYY/MM/DD HH:mm:ss')

    if (mode === 'date') {
      from_date = currentDate.startOf('day').format('YYYY/MM/DD HH:mm:ss')
      to_date = currentDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    setSpinning(true);
    setAppointments(data);
    axios.get(APPOINTMENT_API, {
      params: {
        page: page,
        per_page: 1000,
        from_datetime: from_date,
        to_datetime: to_date
      }
    }).then(response => {
      if (page % 2 === 0 && page < response.data.data.max_page) {
        setAppointments([...data, ...response.data.data.items])
      }
      if (page < response.data.data.max_page) {
        // @ts-ignore
        syncAppointment(page+1, [...data, ...response.data.data.items]);
      } else {
        setAppointments([...data, ...response.data.data.items])
        setSpinning(false)
      }
    })
  }
  useEffect(() => {
    syncAppointment(1, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, mode])

  return (
    <Card style={{ padding: 0 }}
      styles={{
        body: {
          padding: 0,
          maxHeight: '100%',
          height: 'calc(100vh - 255px)',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
      title={
        <Space>
          <Button onClick={() => setCurrentDate(dayjs())}>Hôm nay</Button>
          <Button icon={<i className="fa-solid fa-angle-left"></i>} onClick={() => {
            if (mode === 'date') {
              setCurrentDate(currentDate.add(-1, 'day'))
            } else if (mode === 'week') {
              setCurrentDate(currentDate.add(-1, 'week'))
            }
          }} />
          <DatePicker picker={mode} value={currentDate} onChange={(date) => setCurrentDate(date)} allowClear={false} />
          <Button icon={<i className="fa-solid fa-angle-right"></i>} onClick={() => {
            if (mode === 'date') {
              setCurrentDate(currentDate.add(1, 'day'))
            } else if (mode === 'week') {
              setCurrentDate(currentDate.add(1, 'week'))
            }
          }} />
          {mode === 'week' &&
            <Button
              icon={(zoomMode === 'small' ? <i className="fa-solid fa-up-right-and-down-left-from-center"></i> : <i className="fa-solid fa-down-left-and-up-right-to-center"></i>)}
              onClick={() => setZoomMode(zoomMode === 'small' ? 'large' : 'small')}
            ></Button>
          }
        </Space>
      }
      extra={
        <Segmented
          options={[
            { label: 'Ngày', value: 'date' },
            { label: 'Tuần', value: 'week' },
          ]}
          value={mode}
          onChange={(value) => {
            setMode(value as any)
            setCurrentDate(dayjs())
          }}
        />}
    >
      <Spin spinning={spinning} fullscreen></Spin>
      {mode === 'date' && <CalendarDay appointments={appointments} />}
      {mode === 'week' && <CalendarWeek appointments={appointments} currentDate={currentDate} zoomMode={zoomMode} />}
    </Card >
  )
}
