/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Button, Row, Space, Typography, Spin } from 'antd'
import { getRoleDescription } from 'app/modules/helpers/Common'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

import { useCallback, useState } from 'react'

const { Text } = Typography

const HeaderUserMenu = ({ close = () => { } }) => {
  const { logout, currentUser, auth } = useAuth()

  const [spinning, setSpinning] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const logoutWithSpin = useCallback(async () => {
    setSpinning(true);
    await logout();

    setSpinning(false);

  }, [logout, setSpinning])

  const navigate = useNavigate()

  return (
    <Space direction="vertical" style={{ minWidth: 200 }}>
      <Row className='pt-4 pb-3'>
        <Space direction='vertical' align='center' className='mx-auto' size={0}>
          <Avatar src={currentUser?.avatar || '/media/avatars/blank.png'} size={64} className='mb-2' />
          <Text className='text-hover-primary fs-7 fw-bold'>{currentUser?.fullname}</Text>
          <Text className='text-gray-600 fw-bold d-block fs-8'>{getRoleDescription(auth?.role)}</Text>
        </Space>
      </Row>
      <Button style={{ width: '100%' }} onClick={() => { close(); navigate('/account/me'); }}>Cài đặt tài khoản</Button>
      <Button style={{ width: '100%' }} onClick={() => { setFullscreen(true); logoutWithSpin() }}>Đăng xuất</Button>
      <Spin className="tw-overflow-y-hidden" spinning={spinning} fullscreen={fullscreen}></Spin>
    </Space>
  )
}

export { HeaderUserMenu }
