import { Button, Card, Row, Space, Typography, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useFirebase } from 'app/Firebase'
import { MyUpload } from 'app/modules/apps/chatv3/ChatPage'
import { useAuth } from 'app/modules/auth'
import axios from 'axios'
import dayjs from 'dayjs'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { CHAT_ADD_MESSAGE_V2, CHAT_USER_CONVERSATION, CONVERSATION_DETAIL_API } from '../../../helpers/Common'
import { MessageBubble } from './MessageBubble'

const { Text } = Typography

const preloadSize = 20
const messageInputClassName = 'message-input'

const MessageInput = ({ onEnter }) => {
  const [messageValue, setMessageValue] = useState('')

  const handleMessageChange = (e) => {
    setMessageValue(e.target.value)
  }

  return (
    <TextArea
      className={messageInputClassName}
      rows={3}
      placeholder='Nhập nội dung tin nhắn...'
      variant="borderless"
      style={{ resize: 'none' }}
      onPressEnter={(e) => {
        onEnter(e)
        setMessageValue('')
      }}
      value={messageValue}
      onChange={handleMessageChange}
    />
  )
}

export const ChatWidget = ({ user, fetchCounter }) => {

  const [showNotification, contextHolder] = notification.useNotification()
  const messageList = useRef([] as any[])
  const hasMoreMessage = useRef(true)

  const [, setNeedUpdate] = useReducer((x) => x + 1, 0)

  const lastMessageTime = useRef(dayjs().unix() + 3600)
  const endOfMessage = useRef({} as any)
  const loaderRef = useRef({} as any)

  const [imageList, setImageList] = useState([])
  const [mediaList, setMediaList] = useState([] as any[])
  const [userInfo, setUserInfo] = useState(undefined as any)

  const { auth } = useAuth()
  const { requestNotificationPermission, lastMessage } = useFirebase()

  useEffect(() => {
    requestNotificationPermission()
  }, [requestNotificationPermission])

  const clearInput = useCallback(() => {
    setImageList([])
    setMediaList([])
  }, [setImageList, setMediaList])

  const updateOlder = () => {
    hasMoreMessage.current &&
      updateMessage(lastMessageTime.current).then(({ msg: oldMessage, hasMore }) => {
        hasMoreMessage.current = hasMore
        if (oldMessage.length > 0) {
          lastMessageTime.current = Math.ceil(oldMessage[0].created_at)
          messageList.current = [...oldMessage, ...messageList.current]
          setNeedUpdate()
          setTimeout(() => {
            if (loaderRef.current) {
              document.getElementById(loaderRef.current)?.scrollIntoView()
            }
          }, 100)
        }
      })
  }

  useEffect(() => {
    axios.get(CONVERSATION_DETAIL_API, { params: { conversation_id: user.uid } })
      .then(response => response.data.data)
      .then(data => {
        setUserInfo(new Map(data.attendees.map(attendee => [attendee.uid, { avatar: attendee.avatar, name: attendee.fullname, uid: attendee.uid }])))
      })
  }, [user])

  const loadMore = () => {
    if (messageList.current.length > 0) {
      loaderRef.current = messageList.current[0].uid
    }
    updateOlder()
  }

  const updateMessage = useCallback((timestamp) => {
    return axios
      .get(CHAT_USER_CONVERSATION, {
        params: { conversation_id: user.uid, limit: preloadSize, timestamp: timestamp },
      })
      .then((response) => {
        let items = response.data.data
        let newMessage = items.filter(
          (item) => messageList.current.findIndex((message) => message.uid === item.uid) === -1
        )
        messageList.current.forEach((message, index) => {
          let updatedItem = items.find((item) => item.uid === message.uid)
          if (updatedItem) {
            messageList.current[index] = updatedItem
          }
        })
        console.log(items.length === preloadSize)
        return { msg: newMessage.reverse(), hasMore: items.length === preloadSize }
      })
  }, [user])

  const updateNewer = useCallback(() => {
    return updateMessage(dayjs().unix() + 60).then(({ msg: newMessage }) => {
      if (newMessage.length > 0) {
        messageList.current = [...messageList.current, ...newMessage]
        setNeedUpdate()
        setTimeout(() => {
          if (endOfMessage.current) {
            endOfMessage.current.scrollIntoView()
          }
        }, 200)
      }
    })
  }, [updateMessage])

  useEffect(() => {
    messageList.current = []
    hasMoreMessage.current = true
    try {
      updateNewer().then(() => {
        if (messageList.current.length > 0) {
          lastMessageTime.current = Math.ceil(messageList.current[0].created_at)
          console.log('last message time', lastMessageTime.current)
        }
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }, [updateNewer, user])

  useEffect(() => {
    if (fetchCounter !== 0) {
      updateNewer()
    }
  }, [fetchCounter, user, updateNewer])

  useEffect(() => {
    if (lastMessage && lastMessage.data.conversation_id === user.uid) {
      updateNewer()
    }
  }, [lastMessage, updateNewer, user])

  const onEnter = (e) => {
    let message = e.target.value.trim()
    console.log(message, user)
    if (e.target.value.trim().length > 0 || imageList.length || mediaList.length) {
      const formData = new FormData()

      formData.append('conversation_id', user.uid)
      formData.append('text', message)

      imageList.forEach((img) => formData.append('image', img))
      mediaList.forEach((media) => formData.append('file', media))

      axios({
        method: 'post',
        data: formData,

        url: CHAT_ADD_MESSAGE_V2,
      }).then(() =>
        updateNewer()
      ).catch(err => {
        showNotification.open({
          message: 'Lỗi',
          description: err?.message,
          placement: 'topRight',
          icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,

        })
        /* debugger;*/
      })

      clearInput()
    }

    setTimeout(updateNewer, 300)
    e.preventDefault()
  }

  const onScroll = (e) => {
    if (e.currentTarget.scrollTop === 0 && messageList.current.length > 0) {
      loadMore()
    }
    e.preventDefault();
  }

  const focusInputUponFileAttached = (selector = `.${messageInputClassName}`) => {
    const input = document.querySelector(selector)

    /* @ts-ignore*/
    input?.focus()
  }

  return (
    <Card
      bordered={true}
      styles={{
        body: { height: '100%', overflowY: 'auto', padding: 0 },
        header: { paddingInline: 10 },
      }}
      style={{ height: '100%', display: 'flex', flexDirection: 'column', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0 }}
      title={
        <>
          <Text className='fs-6 fw-bolder text-gray-900 text-hover-primary me-1 lh-1'>{user?.user_fullname}</Text>
          <div className='mb-0 lh-1'>
            <span className='badge badge-success badge-circle w-8px h-8px me-1'></span>
            <span className='fs-8 fw-bold text-gray-400'>Đang online</span>
          </div>
        </>
      }
      actions={[
        <div style={{ paddingInline: 10, flexShrink: 0, textAlign: 'start' }}>
          <Space size='small' className='ms-2 mb-1'>
            <MyUpload
              fileList={imageList}
              setFileList={setImageList}
              accept='image/*'
              beforeUpload={focusInputUponFileAttached}
            >
              <Button type='text' icon={<i className='fa-solid fa-image'></i>}></Button>
            </MyUpload>

            <MyUpload
              fileList={mediaList}
              setFileList={setMediaList}
              accept='.pdf, video/*'
              beforeUpload={focusInputUponFileAttached}
            >
              <Button type='text' icon={<i className='fa-solid fa-paperclip'></i>}></Button>
            </MyUpload>
          </Space>
          <div style={{ background: '#eee', borderRadius: '1rem', overflow: 'clip', display: 'grid' }}>
            <MessageInput onEnter={onEnter} />
            {imageList.length + mediaList.length > 0 &&
              <Row style={{ width: '100%', flexWrap: 'nowrap', overflowX: 'scroll', paddingBottom: 10, paddingTop: 5, marginInline: 10, paddingRight: 20 }}>
                {imageList.map((img, index) => (
                  <div
                    key={index} style={{
                      backgroundImage: `url(${URL.createObjectURL(img)}`,
                      borderRadius: '0.7rem',
                      height: 40,
                      marginRight: 8,
                      marginTop: 5,
                      backgroundSize: 'cover',
                      aspectRatio: '1/1',
                      border: '2px solid #3e97ff'
                    }} >
                    <Row justify="end">
                      <Button style={{
                        borderRadius: 100,
                        marginRight: -12,
                        marginTop: -10,
                      }}
                        icon={<i className="fa-solid fa-trash-can"></i>}
                        size='small'
                        onClick={() => {
                          setImageList(imageList.filter((_, i) => i !== index))
                        }} />
                    </Row>
                  </div>
                ))}
                {mediaList.map((file, index) => (
                  <div
                    key={index} style={{
                      background: '#fff',
                      borderRadius: '0.7rem',
                      height: 40,
                      maxWidth: 200,
                      marginRight: 8,
                      marginTop: 5,
                      border: '2px solid #3e97ff',
                    }} >
                    <Row justify="end">
                      <Button style={{
                        borderRadius: 100,
                        marginRight: -12,
                        marginTop: -10,
                        marginBottom: -8
                      }}
                        icon={<i className="fa-solid fa-trash-can"></i>}
                        size='small'
                        onClick={() => {
                          setMediaList(mediaList.filter((_, i) => i !== index))
                        }} />
                    </Row>
                    <span className="d-inline-block text-truncate mw-100 mx-auto px-3" >
                      <i className="fa-solid fa-paperclip"></i> {file.name}
                    </span>
                  </div>
                ))}
              </Row>
            }
          </div>
        </div>
      ]}
    >
      {/* <Row style={{ paddingInline: 10, height: '100%' }}> */}
      {contextHolder}
      <div style={{ overflowY: 'auto', height: '100%' }} onScroll={onScroll}>
        <div style={{ paddingBottom: 40 }}>
          {userInfo && messageList.current
            .map((message, index) => (
              <MessageBubble
                key={message.uid}
                message={message}
                user={userInfo}
                isSelf={message.sender_id === auth?.uid}
                isLast={index + 1 < messageList.current.length ? messageList.current[index + 1].sender_id !== message.sender_id : true}
                isFirst={index === 0 || messageList.current[index - 1].sender_id !== message.sender_id || dayjs(message.created_at * 1000).diff(dayjs(messageList.current[index - 1].created_at * 1000), 'minute') > 60}
                markTime={index === 0 || dayjs(message.created_at * 1000).diff(dayjs(messageList.current[index - 1].created_at * 1000), 'minute') > 60}
              />
            ))}
          <div ref={endOfMessage} />
        </div>
      </div>
    </Card >
  )
}
