import { Row, Space, Typography } from "antd"
import { Footer } from "antd/es/layout/layout"
import Link from "antd/es/typography/Link"

const { Text } = Typography

export const MasterFooter = () => {
  return (
    <Footer style={{ padding: 0 }}>
      <Row className='container'>
        <Row className='mx-2 w-100 my-5'>
          <Text>{new Date().getFullYear()} &copy; Metadoc</Text>
          <Space className="ms-auto">
            <Link href={"https://metadoc.vn/about/"} target={'_blank'}>Về công ty</Link>
            <Link href={"https://metadoc.vn/contact-us/"} target={'_blank'}>Liên hệ</Link>
          </Space>
        </Row>
      </Row>
    </Footer>
  )
}
