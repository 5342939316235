import axios from 'axios'
import {
  createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState
} from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { FOLLOW_API } from '../../helpers/Common'
import { Permission } from '../../helpers/Permission'
import * as authHelper from './AuthHelpers'
import { AuthModel, UserModel } from './_models'
import { getUserByToken } from './_requests'
import {
  unregisterFirebase
} from 'app/Firebase'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  permission: (action: Permission, resource?: string) => boolean
  following: any[]
  updateFollow: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  permission: () => { return false },
  following: [],
  updateFollow: () => { }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [following, setFollowing] = useState<any[]>([])
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {

    await unregisterFirebase()

    saveAuth(undefined)
    setCurrentUser(undefined)
  };

  axios.interceptors.response.use(response => response, async (err: any) => {

    if (['refresh', 'token'].every(url => !err.config.url.includes(url))) {
      /*
      / delay
      /*/

      await new Promise(resolve => {
        setTimeout(() => {
          resolve('oke');
        }, 500);
      });


      const retryAuth = authHelper.getAuth();

      /* debugger;*/
      if (retryAuth?.accessToken && !err.config.headers.Authorization.includes(retryAuth?.accessToken)) {
        /* debugger;*/
      }

      err.config.headers.Authorization = `Bearer ${retryAuth?.accessToken}`;

      try {
        const instance = axios.create();
        const res = await instance(err.config);
        return Promise.resolve(res);
      } catch (err) {
        logout();
        return Promise.reject(err)
      }

    }
    return Promise.reject(err)
  })

  const updateFollow = () => {
    axios.get(FOLLOW_API, { params: { page: 1 } })
      .then(response => {
        if (response.data.success) {
          setFollowing(response.data.data.items)
        }
      })
  }

  const permission = (action: Permission, resource?: string) => {
    let role = auth?.role
    if (role === 'SUPER_ADMIN_BRANCH') {
      switch (action) {
        case Permission.ChangePassword:
          if (resource === 'DOCTOR' || resource === 'PATIENT' || resource === 'CUSTOMER_CARE' || resource === 'NURSE') return true;
          break;
        case Permission.SelfChangePassword:
          return true;
        case Permission.ChangeOrganizationInfo:
          return true;
        case Permission.ChangeAccountInfo:
          if (resource === 'DOCTOR' || resource === 'PATIENT' || resource === 'CUSTOMER_CARE' || resource === 'NURSE') return true;
          break;
        case Permission.ShowOrganizationMenu:
        case Permission.UpdateSupportDisease:
        case Permission.UpdateForm:
        case Permission.CreateNewAccount:
        case Permission.ManageOrganization:
        case Permission.ChangePatientAccount:
        case Permission.ChangeAppointment:
        case Permission.ViewAccountList:
          return true
      }
    } else if ((role === 'OWNER') || (role === 'SUPER_ADMIN_SYS')) {
      switch (action) {
        case Permission.ChangePassword:
          if (resource === 'SUPER_ADMIN_BRANCH' || resource === 'DOCTOR' || resource === 'PATIENT' || resource === 'CUSTOMER_CARE' || resource === 'NURSE') return true;
          break;
        case Permission.SelfChangePassword:
          return true;
        case Permission.ChangeOrganizationInfo:
          return true;
        case Permission.ChangeAccountInfo:
          if (resource === 'SUPER_ADMIN_BRANCH' || resource === 'DOCTOR' || resource === 'PATIENT' || resource === 'CUSTOMER_CARE' || resource === 'NURSE') return true;
          break;
        case Permission.ShowSystemMenu:
        case Permission.UpdateDiseaseGroup:
        case Permission.CreateNewAccount:
        case Permission.SwichOrganization:
        case Permission.ManageOrganization:
        case Permission.ChangePatientAccount:
        case Permission.ChangeAppointment:
        case Permission.ViewAccountList:
          return true
      }
    } else if (role === 'DOCTOR') {
      switch (action) {
        case Permission.ViewCalendar:
        case Permission.Telehealth:
          return true
      }
    } else if (role === 'CUSTOMER_CARE') {
      switch (action) {
        case Permission.ChangeAccountInfo:
          return true
      }
    }
    return false
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, permission, following, updateFollow }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, updateFollow } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const data = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
            updateFollow()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
