/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import { Link as InternalLink } from 'react-router-dom'
import Link from "antd/es/typography/Link"
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'

import { Input, Form, Card } from "antd"

import {
    registerFirebase
} from 'app/Firebase'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    email: '',
    password: '',
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser, updateFollow } = useAuth()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const auth = await login(values.email, values.password)
                console.log(auth)
                saveAuth(auth)
                const user = await getUserByToken(auth.accessToken)
                console.log(user)
                setCurrentUser(user)
                updateFollow()
                registerFirebase()
            } catch (error) {
                console.error('reject', error)
                saveAuth(undefined)
                setStatus('Đăng nhập không thành công. Vui lòng kiểm tra lại thông tin')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <Card>
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
            >
                {/* begin::Heading */}
                <div className='text-center mb-11'>
                    <h1 className='text-dark fw-bolder mb-3'>Đăng nhập</h1>
                    <div className='text-gray-500 fw-semibold fs-6'>Hệ thống chăm sóc khách hàng</div>
                </div>
                {/* begin::Heading */}

                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}

                {/* begin::Form group */}
                <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                    <Form.Item
                        className=""
                        {...formik.getFieldProps('email')}
                        rules={[{ required: true, }]}
                    >
                        <Input autoComplete={'email'} className="" />
                    </Form.Item>
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className="fv-help-block">
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row mb-3'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
                    <Form.Item
                        className=""
                        {...formik.getFieldProps('password')}
                        rules={[{ required: true, }]}
                    >
                        <Input.Password autoComplete={'new-password'} />
                    </Form.Item>
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Wrapper */}
                <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                    <div />

                    {/* begin::Link */}
                    <InternalLink to='/auth/forgot-password' className='link-primary'>
                        Quên mật khẩu ?
                    </InternalLink>
                    {/* end::Link */}
                </div>
                {/* end::Wrapper */}

                {/* begin::Action */}
                <div className='d-grid mb-10'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className='indicator-label'>Tiếp tục</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Đang xử lý...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Action */}

                <div className='text-gray-500 text-center fw-semibold fs-6'>
                    Chưa có tài khoản ? {' '}
                    <Link href={"https://metadoc.vn/contact-us/"} target={'_blank'} className='link-primary'>
                        Liên hệ hỗ trợ
                    </Link>
                </div>
            </form>
        </Card>
    )
}
