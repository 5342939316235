import {Card, ConfigProvider, Rate, Table} from 'antd'
import Column from 'antd/es/table/Column'
import {APPOINTMENT_HISTORY_API, getRelativeTime} from 'app/modules/helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'

export const DoctorRating = ({doctor}) => {
  const [appointments, setAppointments] = useState([] as any)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    axios.get(APPOINTMENT_HISTORY_API, {
      params: {
        target_user_id: doctor.uid,
        page: page,
        per_page: pageSize,
        status: JSON.stringify(['FINISHED', 'WAITING_CONCLUSION', 'WAITING_TEST_RESULT', 'SUBMITTED_TEST_RESULT']),
      },
    })
      .then(response => response.data.data)
      .then(data => {
        setAppointments(data.items)
        setMaxPage(data.max_page)
      })
  }, [doctor, page])

  return (
    <Card title="Lịch sử đánh giá bác sĩ">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
            },
          },
        }}>
        <Table
          dataSource={appointments}
          rowKey="uid"
          pagination={{
            defaultCurrent: 1,
            total: maxPage * pageSize,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
            current: page,
          }}
          onHeaderRow={() => ({className: 'fw-bolder fs-7 text-uppercase'})}
          onRow={() => ({className: 'text-gray-600 fs-7'})}
          size="middle"
          locale={{emptyText: 'Không có dữ liệu'}}
        >
          <Column title="Khách hàng" dataIndex="patient_info" render={value => value.fullname} />
          <Column title="Thời gian" dataIndex="start_time"
                  render={(value) => dayjs(value * 1000).format('DD/MM/YYYY HH:mm')} />
          <Column title="Thời lượng" render={(_, record: any) => `${(record.end_time - record.start_time) / 60} phút`}
                  responsive={['xl']} />
          <Column title="Nhóm bệnh" dataIndex={['disease_info', 'name']} />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)}
                  responsive={['xl']} />
          <Column title="Số tiền" align="end"
                  render={(_, record: any) => `${record.price.toLocaleString()} ${record.currency_type}`} />
          <Column title="Đánh giá" dataIndex="rating" align="end"
                  render={(value) => value === 0 ? 'Chưa có đánh giá' :
                    <Rate className="fs-7" value={value} disabled />} />
        </Table>
      </ConfigProvider>
    </Card>
  )
}
