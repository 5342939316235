import { Button, Card, Descriptions, Space } from "antd"
import { getGenderDescription, formatPhone, getAge } from "app/modules/helpers/Common"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

export const AppointmentInfo = ({ appointment }) => {
  const navigate = useNavigate()

  const doctor = appointment.doctor_info
  const patient = appointment.patient_info

  return (
    <>
      <Card
        title='Thông tin bệnh nhân'
        extra={
          <Space>
            <Button onClick={() => navigate(`/patient/id/${patient.uid}`)}>Hồ sơ sức khoẻ</Button>
            <Button onClick={() => navigate(`/account/id/${patient.uid}`)}>Tài khoản</Button>
          </Space>
        }
        style={{ marginBottom: 30 }}
        bordered={false}
      >
        <Descriptions layout='vertical' size="small">
          <Descriptions.Item label='Tên đầy đủ'>{patient.fullname}</Descriptions.Item>
          <Descriptions.Item label='Địa chỉ'>{patient.address}</Descriptions.Item>
          <Descriptions.Item label='Ngày sinh'>
            {patient.birthday
              ? `${dayjs(patient.birthday).format('DD-MM-YYYY')} (${getAge(patient.birthday)})`
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label='Giới tính'>
            {getGenderDescription(patient.gender)}
          </Descriptions.Item>
          <Descriptions.Item label='Email'>{patient.email}</Descriptions.Item>
          <Descriptions.Item label='Số điện thoại'>{formatPhone(patient.phone) || ''}</Descriptions.Item>
          {/* <Descriptions.Item label='Chứng chỉ hành nghề'>{doctor.practising_cert}</Descriptions.Item> */}
          {/* <Descriptions.Item label='Chức danh'>{doctor.title.join(", ") || ''}</Descriptions.Item> */}
          {/* <Descriptions.Item label='Học hàm, học vị'>{doctor.degree}</Descriptions.Item> */}
          <Descriptions.Item label="Quốc tịch">{patient.nationality}</Descriptions.Item>
        </Descriptions>
      </Card>
      <Card
        title='Thông tin bác sĩ'
        extra={<Button onClick={() => navigate(`/account/id/${doctor.uid}`)}>Tài khoản</Button>}
        style={{ marginBottom: 30 }}
        bordered={false}
      >
        <Descriptions layout='vertical' size="small">
          <Descriptions.Item label='Tên đầy đủ'>{doctor.fullname}</Descriptions.Item>
          <Descriptions.Item label='Địa chỉ'>{doctor.address}</Descriptions.Item>
          <Descriptions.Item label='Ngày sinh'>
            {doctor.birthday
              ? `${dayjs(doctor.birthday).format('DD-MM-YYYY')} (${getAge(doctor.birthday)})`
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label='Giới tính'>
            {getGenderDescription(doctor.gender)}
          </Descriptions.Item>
          <Descriptions.Item label='Email'>{doctor.email}</Descriptions.Item>
          <Descriptions.Item label='Số điện thoại'>{formatPhone(doctor.phone) || ''}</Descriptions.Item>
          <Descriptions.Item label='Chứng chỉ hành nghề'>{doctor.practising_cert}</Descriptions.Item>
          <Descriptions.Item label='Chức danh'>{doctor.title.join(", ") || ''}</Descriptions.Item>
          <Descriptions.Item label='Học hàm, học vị'>{doctor.degree}</Descriptions.Item>
          <Descriptions.Item label="Quốc tịch">{doctor.nationality}</Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  )
}
