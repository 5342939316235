import { AuthModel } from './_models'

import { jwtDecode } from 'jwt-decode'

import moment from 'moment'

const REFRESH_ACCESS_TOKEN_URL = `${process.env.REACT_APP_API_URL}/api/auth/v2/token/refresh`
const ROTATE_REFRESH_ACCESS_TOKEN_URL = `${process.env.REACT_APP_API_URL}/api/auth/v2/rotation/refresh/token`

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth);

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    localStorage.setItem('debug', lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const isExpired = (expire) => {
  const duration = expire - moment().unix();
  return duration < 0 || duration / 60 < 5;
}

export function setupAxios(axios: any, API_URL = process.env.REACT_APP_API_URL || '') {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: { headers: { Authorization: string }, url: string }) => {

      let newURL = config.url;
      if (API_URL && !config.url.includes(API_URL)) {
        newURL = config.url.replace(process.env.REACT_APP_API_URL || '', API_URL);
      }

      const auth = getAuth();
      let retryAuth;
      if (auth && auth.accessToken) {

        const { accessToken: access_token, refreshToken: refresh_token } = auth;

        // @ts-ignore
        const { expire } = jwtDecode(access_token)

        // @ts-ignore
        const { expire: refresh_expire } = refresh_token ? jwtDecode(refresh_token) : moment().unix();


        // Refresh refresh_token
        //
        //
        if (refresh_token && isExpired(refresh_expire)) {
          const instance = axios.create()
          const refreshRes = await instance.get(ROTATE_REFRESH_ACCESS_TOKEN_URL.replace(process.env.REACT_APP_API_URL || '', API_URL), {
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          });

          const {
            data: {
              success,
              data: { rotation_refresh_token },
            },
          } = refreshRes

          if (success) {
            setAuth({ ...auth, refreshToken: rotation_refresh_token });
          }
        };

        // Refresh access_token
        //
        //
        if (isExpired(expire)) {
          const instance = axios.create();

          try {
            const refreshRes = await instance.get(REFRESH_ACCESS_TOKEN_URL.replace(process.env.REACT_APP_API_URL || '', API_URL), {
              headers: {
                Authorization: `Bearer ${refresh_token}`,
              },
            })

            const {
              data: {
                success,
                data: { token_refresh },
              },
            } = refreshRes;

            if (success) {
              setAuth({ ...auth, accessToken: token_refresh })
              const { headers } = config

              const newConfig = {
                ...config,
                headers: {
                  ...headers,
                  Authorization: `Bearer ${token_refresh}`,
                },
                url: newURL
              };

              return newConfig
            }


          } catch (err: any) {
            const { code } = err;

            if (code === 'ERR_NETWORK') {
              // delay timer
              //
              //
              await new Promise(resolve => {
                setTimeout(() => {
                  resolve('oke');
                }, 500);

                retryAuth = getAuth();
              })

            }
          }

        }

        config.headers.Authorization = `Bearer ${(retryAuth || auth).accessToken}`
      }
      return { ...config, url: newURL }
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use((response: any) => {
    if (response.data.success && response.data.access_token) {
      const auth = getAuth()
      if (!auth || !auth.accessToken || auth.accessToken !== response.data.access_token) {
        setAuth({ ...auth, accessToken: response.data.access_token });
        console.log('ACCESS TOKEN REFRESHED')
      }

      if ((!auth || !auth.refreshToken || auth.refreshToken !== response.data.data.refresh_token) && response.data.data.refresh_token) {
        // @ts-ignore
        setAuth({ ...auth, refreshToken: response.data.data.refresh_token || '' });
        console.log('REFRESH TOKEN REFRESHED')
      }
    } else if (!response.data.success) {
      console.log('Reponse error', response.data)
    }
    return response
  })
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
