import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useLocation } from "react-router-dom";

import {
  NewsfeedListPage
} from 'app/modules/apps/newsfeed/NewsfeedListPage'

import {
  NewPost
} from 'app/modules/apps/newsfeed/NewPost'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Bài viết',
    path: '/newsfeed/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const NewsfeedRoutes = () => {
  const { state } = useLocation();

  return (
    <Routes>
      <Route
        element={<><PageTitle breadcrumbs={profileBreadCrumbs} /><Outlet /></>}
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle>Danh sách bài viết</PageTitle>
              <NewsfeedListPage />
            </>
          }
        />

        <Route
          path='new'
          element={
            <>
              <PageTitle description={state ? 'Chỉnh sửa bài viết' : 'Thêm mới bài viết'}>{state ? 'Chỉnh sửa bài viết' : 'Thêm mới bài viết'}</PageTitle>
              <NewPost />

            </>
          }
        />


      </Route>


    </Routes >
  )
}
