import { HeaderUserMenu } from '_metronic/partials'
import { Badge, Button, Popover, Row, Space } from 'antd'
import { useFirebase } from 'app/Firebase'
import { APPOINTMENT_PENDING_COUNTER_API, CONCLUSION_API, GET_COUNT_NOTIFICATION } from 'app/modules/helpers/Common'
import axios from 'axios'
import { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultTitle } from './DefaultTitle'
import { FollowerMenu } from './FollowerMenu'
import { NotificationMenu } from './NotificationMenu'
import { useAuth } from 'app/modules/auth'
import { Permission } from 'app/modules/helpers/Permission'

const HeaderToolbar = () => {
  const { currentUser, permission } = useAuth()
  const { requestNotificationPermission, lastMessage } = useFirebase()
  const navigate = useNavigate()
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false)
  const [followerMenuOpen, setFollowerMenuOpen] = useState(false)
  const [counter, forceUpdate] = useReducer(x => x + 1, 0)

  const [haveTodo, setHaveTodo] = useState(0)
  const [haveNotification, setHaveNotification] = useState(false)

  useEffect(() => {
    axios.get(APPOINTMENT_PENDING_COUNTER_API)
      .then(response => response.data.data)
      .then(data => {
        setHaveTodo(data.total)
      }).catch(() => {
      })
    axios.get(GET_COUNT_NOTIFICATION)
      .then(response => response.data.data)
      .then(data => setHaveNotification(data.count > 0))
  }, [counter])

  useEffect(() => {
    requestNotificationPermission()
  }, [requestNotificationPermission])


  useEffect(() => {
    axios.interceptors.response.use((response: any) => {
      if (response?.request?.responseURL?.includes(CONCLUSION_API)) {
        forceUpdate()
      }
      return response
    })
  }, [forceUpdate])

  return (
    <Row className="container">
      <Row className="mx-2 w-100">
        <DefaultTitle />
        <Space className="ms-auto" styles={{ item: { height: 80, display: 'flex', alignItems: 'center' } }}>
          <Popover
            content={<NotificationMenu close={() => setNotificationMenuOpen(false)} />}
            trigger="click"
            arrow={false}
            open={notificationMenuOpen}
            placement="bottomRight"
            destroyTooltipOnHide
            overlayInnerStyle={{ padding: 0 }}
            onOpenChange={(open) => {
              setNotificationMenuOpen(open)
            }}
          >
            <Badge dot={haveNotification}>
              <Button type="default" className="bg-white"
                icon={<i className="ki-solid ki-notification text-primary fs-1"></i>} size="large" />
            </Badge>
          </Popover>
          {haveTodo > 0 && ['SUPER_ADMIN_BRANCH'].every(role => !role.includes(currentUser?.role || '')) &&
            <Button type="default" className="bg-white" size="large"
              // icon={<i className={clsx('ki-solid ki-bookmark-2 fa-solid fs-1', { 'text-primary fa-shake': haveTodo > 0 }, { 'text-primary': haveTodo === 0 })}></i>}
              icon={<span className="fw-bolder">{haveTodo}</span>}
              onClick={() => {
                forceUpdate()
                navigate('/calendar/pending')
              }}
            />
          }
          <Popover
            content={<FollowerMenu close={() => setFollowerMenuOpen(false)} />}
            trigger="click"
            arrow={false}
            open={followerMenuOpen}
            placement="bottomRight"
            destroyTooltipOnHide
            overlayInnerStyle={{ padding: 0 }}
            onOpenChange={(open) => {
              setFollowerMenuOpen(open)
            }}
          >
            <Button type="default" className="bg-white"
              icon={<i className="ki-solid ki-address-book text-primary fs-1"></i>} size="large" />
          </Popover>
          {[Permission.ShowSystemMenu, Permission.ShowOrganizationMenu].every(p => !permission(p)) && <Badge dot={lastMessage}>
            <Button type="default" className="bg-white"
              icon={<i className="ki-solid ki-messages text-primary fs-1"></i>} onClick={() => navigate('/chat')}
              size="large"
            />
          </Badge>}
          <Popover content={<HeaderUserMenu close={() => setUserMenuOpen(false)} />} trigger="click" arrow={false}
            open={userMenuOpen} onOpenChange={(open) => setUserMenuOpen(open)} placement="bottomRight">
            <Button type="default" className="bg-white"
              icon={<i className="ki-solid ki-user text-primary fs-1"></i>}
              size="large"
            />
          </Popover>
        </Space>
      </Row>
    </Row>
  )
}

export { HeaderToolbar }
