import { Button, Card, ConfigProvider, List, Tabs } from "antd"
import { NOTIFICATION_API, NOTIFICATION_MARK_SEEN_ALL_API, NOTIFICATION_MARK_SEEN_API, NOTIFICATION_UNREAD_LIST_API, getRelativeTime, APPOINTMENT_DETAIL_API } from "app/modules/helpers/Common"
import axios from "axios"
import clsx from "clsx"
import { useEffect, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"
import { NotificationItem } from "./NotificationItem"

const pageSize = 10

export const NotificationListPage = () => {
  const [notificationList, setNotificationList] = useState([] as any[])
  const [mode, setMode] = useState('all' as 'all' | 'unread')
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [fetchCounter, forceRefresh] = useReducer(x => x + 1, 0);

  const navigate = useNavigate()

  useEffect(() => {
    axios.get(mode === 'all' ? NOTIFICATION_API : NOTIFICATION_UNREAD_LIST_API, { params: { page: page } })
      .then(response => response.data.data)
      .then(data => {
        setNotificationList(data.items)
        setMaxPage(data.max_page)
      })

  }, [mode, page, fetchCounter])

  const handleSeenAll = () => {
    axios.put(NOTIFICATION_MARK_SEEN_ALL_API)
      .then(() => {
        forceRefresh()
      })
  }

  return (
    <Card
      styles={{ body: { paddingInline: 0, paddingTop: 0 } }}
    >
      <ConfigProvider
        theme={{
          components: {
            List: {
              titleMarginBottom: 0,
              metaMarginBottom: 4,
            },
          },
        }}
      >
        <Tabs
          tabBarStyle={{ paddingInline: 24, marginBottom: 0 }}
          tabBarExtraContent={<Button onClick={handleSeenAll}>Đã đọc tất cả</Button>}
          defaultActiveKey="all"
          items={[{
            key: 'all',
            label: 'Tất cả',
          }, {
            key: 'unread',
            label: 'Chưa đọc',
          }]}
          onChange={(key) => {
            setPage(1)
            setMode(key as 'all' | 'unread')
          }}
        />
        <List
          itemLayout="vertical"
          dataSource={notificationList}
          pagination={{
            defaultCurrent: 1,
            total: maxPage * pageSize,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
            current: page,
            position: 'bottom',
            align: 'end',
            size: 'small',
            style: { paddingRight: 15 }
          }}
          // size='small'
          locale={{ emptyText: 'Không có thông báo nào' }}
          renderItem={(item) =>
            <List.Item
              key={item.uid}
              className={clsx({ "bg-light": item.is_seen })}
              styles={{ actions: { marginTop: 0 } }}
              extra={<Button onClick={async () => {
                if (item.entity_action === 'BOOKING_SUCCESS' || item.entity_action === 'UPLOAD_TEST_RESULT' || item.entity_action === 'BOOKING_CHANGE_TIME' || item.entity_action === 'COMPLETED_CAREPLAN') {
                  await axios.put(NOTIFICATION_MARK_SEEN_API, { notification_ids: [item.uid] });

                  if (item.entity_action === 'COMPLETED_CAREPLAN') {

                    const { actor_user_profile: { uid } } = item;

                    navigate(`/patient/id/${uid}/careplan`)

                  } else {
                    const appointmentRes = await axios.get(APPOINTMENT_DETAIL_API, { params: { appointment_id: item.entity_id } });

                    if (appointmentRes.data.success) {
                      navigate(`/calendar/id/${item.entity_id}`)
                    }
                  }



                }
              }}>Xem chi tiết</Button>}
              actions={[
                <span className="fs-8 text-gray-600">{getRelativeTime(item.created_at)}</span>,
                <span className="fs-8 text-gray-600">Hệ thống</span>,
                item.is_seen ? <span className="fs-8 text-gray-600">Đã xem</span> :
                  <Button className="fs-8" size="small" type='default' icon={<i className="fa-solid fa-check"></i>} iconPosition='end'
                    onClick={(e) => {
                      axios.put(NOTIFICATION_MARK_SEEN_API, { notification_ids: [item.uid] })
                        .then(() => {
                          forceRefresh()
                        })
                      e.stopPropagation()
                    }}
                  >Đánh dấu đã xem</Button>
              ]}
              style={{ paddingInline: 24, paddingBlock: 8 }}>
              <NotificationItem item={item} />
            </List.Item>
          }
        />
      </ConfigProvider>
    </Card>
  )
}
