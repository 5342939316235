import { Button, Card, Form, Input, Row, Space } from "antd";
import { DISEASE_GROUP_API } from "app/modules/helpers/Common";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const NewDiseasePage = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const handleFormSubbmit = () => {
    form.validateFields()
      .then((values) => {
        console.log(values);
        axios.post(DISEASE_GROUP_API, { ...values })
          .then(response => {
            console.log(response)
            navigate(`/disease/id/${response.data.data.uid}`)
          })
      }).catch(err => {
        /* TODO: handle API error */
      });
  }

  return (
    <Card title='Thông tin nhóm bệnh mới' actions={[
      <Row justify="end" style={{ paddingRight: 25 }}>
        <Space>
          <Button onClick={() => navigate(-1)}>Huỷ</Button>
          <Button type='primary' onClick={handleFormSubbmit}>Tạo nhóm bệnh</Button>
        </Space>
      </Row>
    ]} >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
      >
        <Form.Item label="Tên nhóm bệnh" name="name" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Mô tả" name="description">
          <Input />
        </Form.Item>
      </Form>
    </Card>
  )
}