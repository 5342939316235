import {Card, Timeline} from 'antd'
import {ACTIVITY_HISTORY_API} from 'app/modules/helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'

export const AppointmentActivity = ({appointment}) => {
  const [activityHistory, setActivityHistory] = useState([] as any[])
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    axios.get(ACTIVITY_HISTORY_API, {params: {entity_id: appointment.uid}}).then((response) => {
      const data = response.data.data
      let addFlag = false
      console.log('activity', data)
      const activities = data.reverse().map((item) => {
        switch (item.entity_action) {
          case 'BOOKING_SUCCESS':
            let description = (
              <>
                <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                <span>{`đã đặt lịch hẹn tư vấn bệnh `}</span>
                <span className="text-primary">{item.data_changed.disease_name}</span>
                <span>{` vào`}</span>
                <span className="text-primary">
                  {' '}
                  {dayjs(item.created_at * 1000).format('HH:mm')}
                </span>
                <span>{` ngày`}</span>
                <span className="text-primary">
                  {' '}
                  {dayjs(item.created_at * 1000).format('DD/MM/YYYY')}
                </span>
              </>
            )
            return {children: description}
          case 'SUGGEST_SERVICE':
            return {
              children: (
                <>
                  <span>{`Bác sĩ `}</span>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã yêu cầu bổ sung dịch vụ cận lâm sàng `}</span>
                  {/* <span className="text-primary">{item.data_changed.disease_name}</span> */}
                  <span>{`vào`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('HH:mm')}
                  </span>
                  <span>{` ngày`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('DD/MM/YYYY')}
                  </span>
                </>
              ),
            }
          case 'UPLOAD_TEST_RESULT':
            return {
              children: (
                <>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã bổ sung kết quả dịch vụ cận lâm sàng `}</span>
                  {/* <span className="text-primary">{item.data_changed.disease_name}</span> */}
                  <span>{`vào`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('HH:mm')}
                  </span>
                  <span>{` ngày`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('DD/MM/YYYY')}
                  </span>
                </>
              ),
            }
          case 'JOIN_CALL':
            return {
              children: (
                <>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã tham gia cuộc gọi tư vấn `}</span>
                  <span className="text-primary">{item.data_changed.disease_name}</span>
                  <span>{` vào`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('HH:mm')}
                  </span>
                  <span>{` ngày`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('DD/MM/YYYY')}
                  </span>
                </>
              ),
            }
          case 'FINISH_CONCLUSION':
            setFinished(true)
            addFlag = true
            return {
              children: (
                <>
                  <span>{`Bác sĩ `}</span>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã hoàn thành kết luận cho ca tư vấn `}</span>
                  {/* <span className="text-primary">{item.data_changed.disease_name}</span> */}
                  <span>{`vào`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('HH:mm')}
                  </span>
                  <span>{` ngày`}</span>
                  <span className="text-primary">
                    {' '}
                    {dayjs(item.created_at * 1000).format('DD/MM/YYYY')}
                  </span>
                </>
              ),
            }
          case 'BOOKING_CHANGE_TIME':
            return {
              children: (
                <>
                  <span>{`Quản lý `}</span>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã thay đổi lịch hẹn tư vấn `}</span>
                  <span>{`từ `}</span>
                  <span className="text-primary">{dayjs(item.data_changed.old_start_time * 1000).format('HH:mm')}</span>
                  <span>{` ngày `}</span>
                  <span
                    className="text-primary">{dayjs(item.data_changed.old_start_time * 1000).format('DD/MM/YYYY')}</span>
                  <span>{` sang `}</span>
                  <span className="text-primary">{dayjs(item.data_changed.start_time * 1000).format('HH:mm')}</span>
                  <span>{` ngày `}</span>
                  <span
                    className="text-primary">{dayjs(item.data_changed.start_time * 1000).format('DD/MM/YYYY')}</span>
                  <span>{`. Lý do thay đổi lịch: `}</span>
                  <span className="text-primary">{item.data_changed.reason_change || 'Không'}</span>
                </>
              ),
            }
          case 'BOOKING_CHANGE_DOCTOR':
            return {
              children: (
                <>
                  <span>{`Quản lý `}</span>
                  <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
                  <span>{`đã thay đổi bác sĩ tư vấn `}</span>
                  <span>{`từ bác sĩ `}</span>
                  <span className="text-primary">{item.data_changed.old_doctor_name}</span>
                  <span>{` sang bác sĩ `}</span>
                  <span className="text-primary">{item.data_changed.doctor_name}</span>
                  <span>{`. Lý do thay đổi bác sĩ: `}</span>
                  <span className="text-primary">{item.data_changed.reason_change || 'Không'}</span>
                </>
              ),
            }
        }
        return {children: 'Không rõ'}
      })
      activities.unshift({children: 'Khởi tạo ca khám tư vấn', color: 'green'})
      addFlag &&
      activities.push({
        children: 'Hoàn tất ca khám tư vấn',
        color: 'green',
        dot: <i className="fa-solid fa-flag-checkered"></i>,
      })

      if (['MISSING_CALL'].includes(appointment.status)) {
        setFinished(true)
        activities.push({
          children: 'Ca khám tư vấn đã huỷ',
          color: 'red',
        })
      }

      setActivityHistory(activities);

      if (!response?.data?.data?.length) {
        setFinished(true);

        activities.push({
          children: 'Hoàn tất ca khám tư vấn',
          color: 'green',
          dot: <i className="fa-solid fa-flag-checkered"></i>,
        })
      }
    })
  }, [appointment])

  return (
    <Card title="Lịch sử hoạt động">
      <Timeline
        items={activityHistory}
        mode="left"
        className="w-100"
        pending={!finished ? 'Đang xử lý...' : undefined}
      />
    </Card>
  )
}
