import { Button, Card, Descriptions } from 'antd'
import dayjs from 'dayjs'
import { useReducer, useState } from 'react'
import { formatPhone, getGenderDescription, getAge } from '../../../helpers/Common'

import { WithShareDataPage } from 'app/modules/apps/patient-management/patient/ShareDataPage'
import { PatientFileUploadModal } from './PatientFileUploadModal'
import { PatientFiles } from './PatientFiles'

export function PatientProfile({ account }) {
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false)
  const [counter, forceUpdate] = useReducer((x) => x + 1, 0)

  return (
    account && (
      <>
        <Card
          title='Thông tin cá nhân'
          style={{ marginBottom: 30 }}
        >
          <Descriptions column={2} layout='vertical' size='small'>
            <Descriptions.Item label='Tên đầy đủ'>{account.fullname}</Descriptions.Item>
            <Descriptions.Item label='Địa chỉ'>{account.address}</Descriptions.Item>
            <Descriptions.Item label='Ngày sinh'>
              {account.birthday
                ? `${dayjs(account.birthday).format('DD-MM-YYYY')} (${getAge(account.birthday)})`
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Giới tính'>
              {getGenderDescription(account.gender)}
            </Descriptions.Item>
            <Descriptions.Item label='Số điện thoại'>
              {formatPhone(account.phone)}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>{account.email}</Descriptions.Item>
            <Descriptions.Item label='Quốc tịch'>{account.nationality}</Descriptions.Item>
            {/* <Descriptions.Item label='Nhóm bệnh'>
              {account.health.pathological_medical_history.replaceAll(';', ' - ')}
            </Descriptions.Item> */}
          </Descriptions>
        </Card>

        <WithShareDataPage
          account={account}
          extra={
            <Card
              title='Hồ sơ y tế'
              // styles={{body: {padding: 0}}}
              extra={
                <Button
                  icon={<i className='fa-solid fa-cloud-arrow-up'></i>}
                  onClick={() => setIsModalUploadOpen(true)}
                >
                  Thêm hồ sơ
                </Button>
              }>
              <PatientFiles account={account.uid} refreshCounter={counter} />
            </Card>}
        />
        <PatientFileUploadModal
          open={isModalUploadOpen}
          close={(refresh) => {
            setIsModalUploadOpen(false)
            if (refresh) {
              forceUpdate()
            }
          }}
          account={account.uid} />
      </>
    )
  )
}
